import React, { useEffect, useState } from 'react';
import { InsideBannerList } from './Innerbanner';
import Header from '../common/Header';
import Footer from '../common/Footer';

const PrivacyPolicy = () => {
    const [state, setState] = useState({});
    useEffect(() => {
        let privacyInsideSlider = InsideBannerList.filter((item) => item.title === 'privacy-policy');
        setState(privacyInsideSlider[0]);
    }, []);

    return (
        <div>
            <Header/>
                <section className="inner-banner" style={{ backgroundImage: `url(${state.image})` }} >
                    <div className="container">
                        <div className="row">
                            <h2 className="text-white text-uppearcase inner-banner-title fs-1 fw-semibold text-center position-absolute top-50 start-50 translate-middle">{state.heading}</h2>
                        </div>
                    </div>
                </section>
                <section className="py-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 mb-4">
                                <p className="website-para-textstyle text-dark text-justify lh-lg mb-4">Atum Solar Roof takes your privacy very seriously. This privacy policy (“Privacy Policy”) is intended to inform you about the information that is collected from you when you visit website and applications owned and operated by Atum Solar Roof(the “Platform”); how this information may be used and disclosed; and how your information is protected. atum.in and is a property of and is hosted and operated by Visaka Industries Limited., a company incorporated under the Indian Companies Act, 1956 and having its registered office at Visaka Towers,1-8-303/69/3, Sardar Patel Rd, Secunderabad, Telangana – 500003.</p>
                                <h3 className="text-capitalize primary-text text-start mb-3">Acceptance of terms</h3>
                                <p className="website-para-textstyle text-dark text-justify lh-lg">This policy contains provisions that define your limits, legal rights and obligations with respect to your use of and participation on the Platform, including the classified advertisements, forums, various email functions, and Internet links, and all content and Atum Solar Roof services available on the Platform. The Privacy Policy applies to all users of the Platform, including users who have access and/or register on the Platform and users who are also contributors of content, information, private and public messages, advertisements, and other materials or services on the Platform.</p>
                            </div>
                            <div className="col-12 mb-4">
                                <h3 className="text-capitalize primary-text text-start mb-3">Information we collect:</h3>
                                <h5 className="text-capitalize primary-text text-start fs-5 mb-1">1. Personal information</h5>
                                <ul className="list-unstyled ms-2">
                                    <li className="mb-2 d-flex lh-lg text-justify">
                                        <span className="icon-font primary-text fs-6 fw-normal pe-2">&#10004;</span>
                                        <span className="website-span-textstyle">Atum Solar Roof grants you a revocable, non-exclusive, non-transferable, limited license to download, install and use the website strictly in accordance with the terms of this Agreement. “Personal information” is defined to include information that may be used to readily identify or contact you such as name, address, PIN code, email address, phone number, order details, user records, etc. that is otherwise not publicly available. When you sign up on the Platform we collect personal information from (i) users who are interested in availing our services, (ii) design partners who offer their design services to Atum Solar Roof customers (“Design Partners”), and (iii) service partners offering their services (“Service Partners”).</span>
                                    </li>
                                    <li className="mb-2 d-flex lh-lg text-justify">
                                        <span className="icon-font primary-text fs-6 fw-normal pe-2">&#10004;</span>
                                        <span className="website-span-textstyle">Personal information collected from Design Partners and Service Partners may be partially or completely accessible to users on the Platform or those availing our services. Personal information from users availing our services is partially or completely available to relevant Design Partner(s), Service Partner(s) and employee(s) of Atum Solar Roof on a need to know basis.</span>
                                    </li>
                                    <li className="mb-2 d-flex lh-lg text-justify">
                                        <span className="icon-font primary-text fs-6 fw-normal pe-2">&#10004;</span>
                                        <span className="website-span-textstyle">All information disclosed by you shall be deemed to be disclosed willingly and without any coercion. No liability pertaining to the authenticity/ genuineness/ misrepresentation/ fraud/ negligence, etc. of the information disclosed shall lie on Atum Solar Roof nor will Atum Solar Roof in any way be responsible to verify any information obtained from you.</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-12 mb-4">
                                <h5 className="text-capitalize primary-text text-start fs-5 mb-1">2. Personal information from social networking sites</h5>
                                <ul className="list-unstyled ms-2">
                                    <li className="mb-2 d-flex lh-lg text-justify">
                                        <span className="icon-font primary-text fs-6 fw-normal pe-2">&#10004;</span>
                                        <span className="website-span-textstyle">You may log into our Platform through your Facebook, Google or Twitter account. If you do so, you must enter the email address and password that you use for your account. We will ask that you grant us permission to access and collect your Facebook basic information (this includes your name, profile picture, gender, networks, user IDs, list of friends, date of birth, email address, and any other information you have set to public on your Facebook account). If you allow us to have access to this information, then we will have access to this information even if you have chosen not to make that information public.</span>
                                    </li>
                                    <li className="mb-2 d-flex lh-lg text-justify">
                                        <span className="icon-font primary-text fs-6 fw-normal pe-2">&#10004;</span>
                                        <span className="website-span-textstyle">We store the information that we receive from Facebook with other information that we collect from you or receive about you. Facebook controls the information it collects from you. For information about how Facebook may use and disclose your information, including any information you make public, please consult Facebook’s privacy policy. We have no control over how any third-party site uses or discloses the personal information it collects about you.</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-12 mb-4">
                                <h5 className="text-capitalize primary-text text-start fs-5 mb-1">3. Calls and text messages</h5>
                                <ul className="list-unstyled ms-2">
                                    <li className="mb-2 d-flex lh-lg text-justify">
                                        <span className="icon-font primary-text fs-6 fw-normal pe-2">&#10004;</span>
                                        <span className="website-span-textstyle">Atum Solar Roof enables users to call and chat with the Design Partners, Service Partners through the Platform. As otherwise described in this policy, your contact information may be accessible to a Design Partner or Service Partner for the sole purpose of providing services. In addition, regarding the calls or chats, we collect information including the date and time of the call/chat message(s), and the content of the calls and chat messages to improve our customer service and quality of services. Atum Solar Roof may also use this information for resolving disputes between you and Design Partners or Service Partners or other users, or for safety and security purposes, and for analytics.</span>
                                    </li>
                                    <li className="mb-2 d-flex lh-lg text-justify">
                                        <span className="icon-font primary-text fs-6 fw-normal pe-2">&#10004;</span>
                                        <span className="website-span-textstyle">We respect children’s privacy. We do not knowingly or intentionally collect personal information from children under age 13. By accessing and/or registering on the Platform, you have represented and warranted that you are either 18 years of age or using the Platform with the supervision of a parent or guardian. If you are under the age of 13, please do not submit any personal information to us, and rely on a parent or guardian to assist you.</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-12 mb-4">
                                <h5 className="text-capitalize primary-text text-start fs-5 mb-1">4. Age</h5>
                                <ul className="list-unstyled ms-2">
                                    <li className="mb-2 d-flex lh-lg text-justify">
                                        <span className="icon-font primary-text fs-6 fw-normal pe-2">&#10004;</span>
                                        <span className="website-span-textstyle">You may log into our Platform through your Facebook, Google or Twitter account. If you do so, you must enter the email address and password that you use for your account. We will ask that you grant us permission to access and collect your Facebook basic information (this includes your name, profile picture, gender, networks, user IDs, list of friends, date of birth, email address, and any other information you have set to public on your Facebook account). If you allow us to have access to this information, then we will have access to this information even if you have chosen not to make that information public.</span>
                                    </li>
                                    <li className="mb-2 d-flex lh-lg text-justify">
                                        <span className="icon-font primary-text fs-6 fw-normal pe-2">&#10004;</span>
                                        <span className="website-span-textstyle">We store the information that we receive from Facebook with other information that we collect from you or receive about you. Facebook controls the information it collects from you. For information about how Facebook may use and disclose your information, including any information you make public, please consult Facebook’s privacy policy. We have no control over how any third-party site uses or discloses the personal information it collects about you.</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-12 mb-4">
                                <h5 className="text-capitalize primary-text text-start fs-5 mb-1">5. Refer a friend</h5>
                                <ul className="list-unstyled ms-2">
                                    <li className="mb-2 d-flex lh-lg text-justify">
                                        <span className="icon-font primary-text fs-6 fw-normal pe-2">&#10004;</span>
                                        <span className="website-span-textstyle">Atum Solar Roof offers the opportunity to any user to share details of anyone whom they think may be interested in Atum Solar Roof or our services. This information is recorded and used only to communicate with the referee using mediums including phone calls and email, based on the details provided to Atum Solar Roof. We believe that it is within the purview of the referrer to seek authorization to share the personal information of the referee. When the information is shared with Atum Solar Roof, it is deemed that the authorization has already been obtained by the referrer.</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-12 mb-4">
                                <h5 className="text-capitalize primary-text text-start fs-5 mb-1">6. Communications</h5>
                                <ul className="list-unstyled ms-2">
                                    <li className="mb-2 d-flex lh-lg text-justify">
                                        <span className="icon-font primary-text fs-6 fw-normal pe-2">&#10004;</span>
                                        <span className="website-span-textstyle">During your interaction with Atum Solar Roof, you may also participate in polls, surveys and feedback and personal information including call logs during such interactions will be collected. Further, when you communicate with us (via email, phone, texts, through the Platform or otherwise), we may maintain a record of your communication. When you use the Platform to communicate with others, we may collect and maintain a copy of those communications, in accordance with applicable law. Such information obtained from a user is gathered and utilized to ensure a greater experience for users and partners.</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-12 mb-4">
                                <h5 className="text-capitalize primary-text text-start fs-5 mb-1">7. Payment, billing and financial information</h5>
                                <ul className="list-unstyled ms-2">
                                    <li className="mb-2 d-flex lh-lg text-justify">
                                        <span className="icon-font primary-text fs-6 fw-normal pe-2">&#10004;</span>
                                        <span className="website-span-textstyle">When you create an account with Atum Solar Roof and start (i) availing services from us, our Design Partners and or Service Partners (ii) offer your services as a Design Partner or Service Partner, you may be required to disclose information such as bank details, PAN card, Aadhaar card, credit card details, etc. in order for us to process your request. Additionally, Atum Solar Roof may also collect information related to the manner and mode of payments usually adopted by you on the Platform, including details of your bank account, credit card, and debit card. The financial information collected may vary in accordance with your interaction with the Platform and our Partners.</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-12 mb-4">
                                <h5 className="text-capitalize primary-text text-start fs-5 mb-1">8. Device and location information</h5>
                                <ul className="list-unstyled ms-2">
                                    <li className="mb-2 d-flex lh-lg text-justify">
                                        <span className="icon-font primary-text fs-6 fw-normal pe-2">&#10004;</span>
                                        <span className="website-span-textstyle">We collect information from your devices (computers, mobile phones, tablets, etc.), including information about how you interact with our services and information that allows us to recognize and associate your activity across devices and services. This information includes device-specific identifiers and information such as location, IP address, cookie information, mobile device, and advertising identifiers, browser version, operating system type and version, mobile network information, device settings, and software data. We may recognize your devices to provide you with personalized experiences and advertising across the devices you use. In addition, the information may also include your location.</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-12 mb-4">
                                <h5 className="text-capitalize primary-text text-start fs-5 mb-1">9. Information from cookies and other tracking methods</h5>
                                <ul className="list-unstyled ms-2">
                                    <li className="mb-2 d-flex lh-lg text-justify">
                                        <span className="icon-font primary-text fs-6 fw-normal pe-2">&#10004;</span>
                                        <span className="website-span-textstyle">This Platform employs cookies, web beacons and other tracking technologies to speed your navigation of the Platform, recognize you and your access privileges, and track your Platform usage. These help us in Platform analytics to give you a better experience. The same may be used to evaluate the success of our advertising campaigns. This includes information but is not limited to, tracking how frequently you visit our Platform and use our services, time spent on the Platform, pages visited, links clicked, etc. We may use third-party advertising companies to help us tailor site content to users or to serve ads on our behalf. These companies may employ cookies and web beacons to measure advertising effectiveness (such as which web pages are visited or what products are purchased and in what amount). Any information that these third parties collect via cookies and web beacons is not linked to any personal information collected by us.</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-12 mb-4">
                                <h3 className="text-capitalize primary-text text-start mb-3">Information from other sources </h3>
                                <ul className="list-unstyled ms-2">
                                    <li className="mb-2 d-flex lh-lg text-justify">
                                        <span className="icon-font primary-text fs-6 fw-normal pe-2">&#10004;</span>
                                        <span className="website-span-textstyle"> Feedback including surveys and ratings</span>
                                    </li>
                                    <li className="mb-2 d-flex lh-lg text-justify">
                                        <span className="icon-font primary-text fs-6 fw-normal pe-2">&#10004;</span>
                                        <span className="website-span-textstyle">Atum Solar Roof business partners through which you create or access your Atum Solar Roof accounts, such as payment providers, social media services, or apps or websites who use Atum Solar Roof APIs or whose API Atum Solar Roof uses</span>
                                    </li>
                                    <li className="mb-2 d-flex lh-lg text-justify">
                                        <span className="icon-font primary-text fs-6 fw-normal pe-2">&#10004;</span>
                                        <span className="website-span-textstyle"> Financial services providers </span>
                                    </li>
                                    <li className="mb-2 d-flex lh-lg text-justify">
                                        <span className="icon-font primary-text fs-6 fw-normal pe-2">&#10004;</span>
                                        <span className="website-span-textstyle"> Marketing service providers</span>
                                    </li>
                                </ul>
                                <p className="website-para-textstyle lh-lg text-justify">Atum Solar Roof may combine the information collected from these sources with other information in its possession. By choosing to provide any and all of the aforesaid information to Us, you are giving Atum Solar Roof permission to use and/or store such information consistent with this Privacy Policy. Further, Atum Solar Roof shall be entitled to retain your Personal Information for such duration as may be required for the purposes specified hereunder and shall be used us only in accordance with this Privacy Policy.</p>
                            </div>
                        </div>
                    </div>
                </section>
            <Footer/>
        </div>
    )
}

export default PrivacyPolicy