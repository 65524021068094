import React, { useRef, useState, useEffect } from 'react';
import { API_URL } from '../../../Config';
import axios from 'axios';

const MobileVerificationComp = ({ setBecomeDealerSubmitBtnDisabled, mobileChangeEvent, formik }) => {
    const otpCode = useRef(0);
    const [mobileVerified, setMobileVerified] = useState(false);
    const [mobileOtp, setMobileOtp] = useState(false);
    const [mobileOTPError, setMobileOTPError] = useState();

    const sendOpt = () => {
        try{
            const sendOtpHttpRequest = new Promise((resolve, reject) => { 
                const formData = new FormData();
                formData.append("phone_number", formik.values.phone)
                formData.append("templateId", "1107167274516378566")
                axios.post(`${API_URL}communication/sms/send/otp`, formData).then(res => { if(res.data.status === 200){ resolve(res.data); }else{ reject(res.data.message); } }).catch( err => reject(err) );
            });
            sendOtpHttpRequest.then(
                (e)=>{ setMobileOtp(e.data.response_data.otp); },
                (e)=>{ setMobileOTPError(`Failed to send the OTP because of ${e}`); }
            );
        } catch (error) {
            setMobileOTPError(`Failed to send the OTP because of ${error}`);
        }
    };

    const checkOpt = () => {
        if(mobileOtp === parseInt(otpCode.current.value)){
            setMobileVerified(true);
            setMobileOtp(false);
            setBecomeDealerSubmitBtnDisabled(false);
            setMobileOTPError("");
        }else{ setMobileOTPError("Entered OTP is invalid, please try again."); }
    }

    useEffect(() => {
        setBecomeDealerSubmitBtnDisabled(true);
        setMobileVerified(false);
        setMobileOtp(false);
        setMobileOTPError("");
        otpCode.current.value = '';
    }, [formik.values.phone, setBecomeDealerSubmitBtnDisabled]);

    return (
        <>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 py-2 px-2">
                <input type={ (mobileVerified) ? 'text' : (mobileOtp ? 'hidden' : 'text') } className="form-control" name="phone" maxLength="10" placeholder="Mobile Number*" onChange={mobileChangeEvent} value={formik.values.phone} />
                <input type={ (mobileVerified) ? 'hidden' : (mobileOtp ? 'text' : 'hidden') } ref={otpCode} className="form-control" name="otp" placeholder="Enter OTP*" />
                { formik.errors.phone ? <span className="text-danger fs-8">{formik.errors.phone}</span> : null }
                { mobileOTPError ? <span className="text-danger fs-8">{mobileOTPError}</span> : null }
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 py-2 px-2 d-flex justify-content-center justify-content-md-start">
                <label className="becameDealerBtnBg1">
                    { (!mobileVerified && !mobileOtp) && <button type='button' disabled={!formik.values.phone ? true : (formik.errors.phone ? true : false)} onClick={sendOpt} id="send_otp" className="becameDealerBTN1 btn btn-danger fw-normal global-primary-bg m-0 btn-padding white-text"><b>Send OTP</b></button> }
                    { mobileOtp && <button type='button' disabled={!formik.values.phone ? true : (formik.errors.phone ? true : false)} onClick={checkOpt} id="send_otp" className="becameDealerBTN1 btn btn-warning fw-normal global-primary-bg m-0 btn-padding black-text"><b>Verify</b></button> }
                    { mobileVerified && <span id="send_otp" className="becameDealerBTN1 btn btn-success fw-normal global-primary-bg m-0 btn-padding white-text"><b>Verified</b></span> }
                </label>
            </div>
        </>
    )
}

export default MobileVerificationComp;