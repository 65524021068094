import React, { useEffect, useState } from 'react';
import { InsideBannerList } from './Innerbanner';
import { CDN_URL } from '../../../Config';
import Header from '../common/Header';
import Footer from '../common/Footer';

const NewsList = [
  {
    id:1 ,
    image: CDN_URL+'visaka/atum/website/assets/images/news/1.png',
    title: 'Visaka Industries donates solar powered street vendor carts',
    date: 'January 29, 2021',
    name: 'Our Bureau',
  },
  {
    id: 2,
    image: CDN_URL+'visaka/atum/website/assets/images/news/2.png',
    title: 'ATUM - Integrated Solar Roof Division Acquires 20 Years Patent Rights',
    date: 'August 27, 2020',
    name: 'News Bureau',
  },
  {
    id: 3,
    image: CDN_URL+'visaka/atum/website/assets/images/news/3.png',
    title: 'Visaka Industries Granted 20-year Patent Rights for ATUM Solar Roof',
    date: 'September 16, 2020',
    name: 'Saur News Bureau',
  },
  {
    id: 4,
    image: CDN_URL+'visaka/atum/website/assets/images/news/4.png',
    title: 'Can ATUM Bridge The Rooftop Solar Gap for India?',
    date: 'January 11, 2021',
    name: 'Prasanna Singh',
  },
  {
    id: 5,
    image: CDN_URL+'visaka/atum/website/assets/images/news/5.png',
    title: 'Vamsi Gaddam, the changemaker aiding sustainability',
    date: 'August 17, 2020',
    name: 'Forbes India',
  },
  {
    id: 6,
    image: CDN_URL+'visaka/atum/website/assets/images/news/6.png',
    title: 'Visaka unveils solar roof panel on cement base',
    date: 'August 31, 20217',
    name: 'The Hindu , Hyderabad',
  }
];

const NewsAndEvent = () => {
  const [state, setState] = useState({});
  useEffect(() => {
    let newsInsideSlider = InsideBannerList.filter((item) => item.title === 'news');
    setState(newsInsideSlider[0]);
  }, []);

  return (
    <div>
      <Header/>
        <section className="inner-banner" style={{ backgroundImage: `url(${state.image})` }} >
          <div className="container">
            <div className="row">
              <h2 className="text-white text-uppearcase inner-banner-title fs-1 fw-semibold text-center position-absolute top-50 start-50 translate-middle">{state.heading}</h2>
            </div>
          </div>
        </section>
        <section className="news py-5 bg-gray">
          <div className="container">
            <div className="row d-flex gy-4">
              { NewsList && NewsList.map((item, index) => 
              <div className="col-12 col-md-6 col-lg-4" key={index}>
                <div className="bg-white border rounded shadow">
                  <div className="entry-content">
                    <div className="entry-img-wrap border-bottom border-danger border-2">
                      <div className="entry-img p-2">
                          <div className="news_img" style={{ backgroundImage: `url(${item.image})` }}></div>
                      </div>
                    </div>
                    <div className="entry-title px-3 py-2">
                      <h5 className="fs-6 fw-semibold text-decoration-none text-black m-0 p-0 pt-2">{item.title}</h5>
                      <div className="project-cat d-flex align-items-center pt-2">
                        <svg xmlns="http://www.w3.org/2000/svg" height="14" width="14" viewBox="0 0 448 512" fill="var(--gray_color)"><path d="M128 0c17.7 0 32 14.3 32 32V64H288V32c0-17.7 14.3-32 32-32s32 14.3 32 32V64h48c26.5 0 48 21.5 48 48v48H0V112C0 85.5 21.5 64 48 64H96V32c0-17.7 14.3-32 32-32zM0 192H448V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V192zm64 80v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm128 0v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H208c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H336zM64 400v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H208zm112 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H336c-8.8 0-16 7.2-16 16z"/></svg>
                        <span className="text-gray ps-1">{item.date}</span>
                      </div>
                      <p className="fw-bold primary-text m-0 p-0 pt-2">{item.name}</p>
                    </div>
                  </div>
                </div>
              </div> )}
            </div>
          </div>
        </section>
      <Footer/>
    </div>
  )
}

export default NewsAndEvent