import React from 'react';
import { useLocation } from 'react-router-dom';
import Header from '../common/Header';
import Footer from '../common/Footer';

const SolarPanels = () => {
    const location = useLocation();
    const selecteddata = location.state.statesdata;

    let atumHtmlDesc = new DOMParser().parseFromString(selecteddata.atum_cpu_desc, 'text/html').body.childNodes;

    const convertJSX = htmlData => {
        return htmlData.map((h, hIndex)=>{
            let attributeObj = {};
            const {attributes, localName, childNodes, nodeValue} = h;
            if(attributes){
                Array.from(attributes).forEach(attribute => {
                    if(attribute.name === "style"){
                        let styleAttributes = attribute.nodeValue.split(";");
                        let styleObj = {};
                        styleAttributes.forEach(attribute => { let [key, value] = attribute.split(":"); styleObj[key] = value; });
                        attributeObj[attribute.name] = styleObj;
                    } else if(attribute.name === "class") { attributeObj['className'] = attribute.nodeValue; }
                    else { attributeObj[attribute.name] = attribute.nodeValue; }
                });
            }
            attributeObj['key'] = hIndex;
            return localName ? React.createElement(localName, attributeObj, childNodes && Array.isArray(Array.from(childNodes)) ? convertJSX(Array.from(childNodes)) : [] ) : nodeValue;
        });
    }

    return (
        <div>
            <Header/>
                <section className="solar-panel-main bg-dark position-relative h-250"></section>
                <div className="solar-panel bg-white rounded-3 p-3 p-lg-5 mb-5 mx-auto d-block shadow">
                    <div className="row d-block d-lg-flex align-items-center cpu-img">
                        <div className="col-12 col-md-12 col-lg-4 col-xl-4">
                            <div className="cpu-state-img mb-3 mb-lg-0">
                                <img src={selecteddata.atum_cpu_image} className="w-100 mx-auto d-block p-2" alt={selecteddata.atum_cpu_state} />
                            </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-8 col-xl-8">
                            <h5 className="text-dark mb-4">
                                <span className="pb-3 fw-light lh-base">{selecteddata.atum_cpu_sub_title} <span className="primary-text fw-bold ps-1">{selecteddata.atum_cpu_state}</span></span>
                            </h5>   
                            <div className="stateTable w-100" dangerouslySetInnerHTML={{__html: selecteddata.atum_cpu_sub_desc}}></div>
                        </div>
                    </div>
                </div>
                <section>
                    <div className="container">
                        <div className="row">
                            <div className="w-100 pb-5">
                                <p className="website-para-textstyle text-justify">{convertJSX(Array.from(atumHtmlDesc))}</p>
                            </div>
                        </div>
                    </div>
                </section>
            <Footer/>
        </div>
    )
}

export default SolarPanels