import React, { useEffect, useState } from 'react';
import { InsideBannerList } from './Innerbanner';
import Header from '../common/Header';
import Footer from '../common/Footer';

const Contact = () => {
  const [state, setState] = useState('');

  useEffect(() => {
    let blogInsideSlider = InsideBannerList.filter((item) => item.title === 'contact');
    setState(blogInsideSlider[0]);
  }, []);

  return (
    <div>
      <Header/>
        <section className="inner-banner" style={{ backgroundImage: `url(${state.image})` }} >
          <div className="container">
            <div className="row">
              <h2 className="text-white text-uppearcase inner-banner-title fs-1 fw-semibold text-center position-absolute top-50 start-50 translate-middle">{state.heading}</h2>
            </div>
          </div>
        </section>
        <section className="contact bg-gray py-5">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2 mt-sm-2 mt-md-0 mt-lg-0 mt-xl-0">
                <div className="row bg-white rounded-3 shadow px-3 py-3">
                  <div className="col-12 pb-0 d-flex align-items-center justify-content-center">
                    <h2 className="primary-text text-capitalize all-heading-text">Our Location</h2>
                  </div>
                  <div className="col-12">
                    <ul className="list-unstyled d-flex flex-column align-items-center justify-content-center">
                      <li className="d-flex align-items-start lh-24 mb-2">
                        <span className="d-flex align-items-center">
                          <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" fill="red" className="pe-2 fs-5"><path d="M280 0V24 65.5C366.8 76.3 435.7 145.2 446.5 232H488h24v48H488 446.5C435.7 366.8 366.8 435.7 280 446.5V488v24H232V488 446.5C145.2 435.7 76.3 366.8 65.5 280H24 0V232H24 65.5C76.3 145.2 145.2 76.3 232 65.5V24 0h48zM112 256a144 144 0 1 0 288 0 144 144 0 1 0 -288 0zm192 0a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm-144 0a96 96 0 1 1 192 0 96 96 0 1 1 -192 0z"></path></svg>
                        </span>
                        <span className="website-para-textstyle text-gray text-black fw-semibold text-center fs-6">Visaka Industries, 1-8-303/69/3, Visaka Tower, S P Road, <br />Secunderabad, India, Telangana - 500003.</span>
                      </li>
                      <li className="d-flex align-items-start lh-24 mb-2">
                        <a href="tel:+919100682886" className="website-para-textstyle text-gray text-black fw-semibold text-center"><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512" fill="red" className="ps-1 pe-2 fs-6"><path d="M80 48c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H304c8.8 0 16-7.2 16-16V64c0-8.8-7.2-16-16-16H80zM16 64C16 28.7 44.7 0 80 0H304c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H80c-35.3 0-64-28.7-64-64V64zM192 392a24 24 0 1 1 0 48 24 24 0 1 1 0-48z"></path></svg><span className="website-span-textstyle"> +91 91006 82886</span></a>
                      </li>
                      <li className="d-flex align-items-start lh-24 mb-0">
                        <a href="mailto:atum@visaka.in" className="website-para-textstyle text-gray text-black fw-semibold text-center"><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" fill="red" className="pe-2 fs-6"><path d="M498.1 5.6c10.1 7 15.4 19.1 13.5 31.2l-64 416c-1.5 9.7-7.4 18.2-16 23s-18.9 5.4-28 1.6L284 427.7l-68.5 74.1c-8.9 9.7-22.9 12.9-35.2 8.1S160 493.2 160 480V396.4c0-4 1.5-7.8 4.2-10.7L331.8 202.8c5.8-6.3 5.6-16-.4-22s-15.7-6.4-22-.7L106 360.8 17.7 316.6C7.1 311.3 .3 300.7 0 288.9s5.9-22.8 16.1-28.7l448-256c10.7-6.1 23.9-5.5 34 1.4z"></path></svg><span className="website-span-textstyle">atum@visaka.in</span></a>
                      </li>
                    </ul>
                    <div className="sticky-social contact-us-social mt-4">
                      <div className="map">
                        <iframe title="frame1" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d487212.210478736!2d78.478572!3d17.443175!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9b07c2a6f8eb%3A0x5b6c6285dfc2089b!2sVisaka%20Industries%20Limited!5e0!3m2!1sen!2sin!4v1696657096077!5m2!1sen!2sin" width="100%" height="350" className="border-0 rounded-3" allowFullScreen></iframe>
                      </div>
                    </div>
                    <div className="row pt-2">
                      <div className="text-center">
                        <button className="btn btn-danger primary-bg py-2 px-4 fw-semibold" type="button"
                          data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">Leave A Message
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      <Footer/>
    </div>
  )
}

export default Contact