import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { CDN_URL } from '../../../../Config';
import { API_URL } from '../../../../Config';
import MobileVerificationComp from '../../common/MobileVerificationComp';

const BecomePartnerDynamicForm = ({ formInputs, dynamicInputs }) => {
    const [becomeDealerSubmitBtnDisabled, setBecomeDealerSubmitBtnDisabled] = useState(false);
    const [stDst, setstDst] = useState();
    const [distData, setDistData] = useState();
    const navigate = useNavigate();
    
    // State District API Intigration Start
    const fetchStateDistrictInfo = async () => {
        const stateDistrictRes = await fetch(CDN_URL + "visaka/common/json/indianStatesCities.json");
        const stateDistrictResData = await stateDistrictRes.json();
        setstDst(stateDistrictResData);
    }
    const handleStateDistrict = (e) => {
        const getDistrict = stDst.filter((distitem) => distitem.State === e);
        setDistData(getDistrict);
    }
    
    const formik = useFormik({
        initialValues: formInputs,
        enableReinitialze: true,
        validateOnChange: false,
        validate: (values) => {
            let errors = {};
            if (!values.first_name) { errors.first_name = 'First name is required'; }
            if (!values.last_name) { errors.last_name = 'Last name is required'; }
            if (!values.email) { errors.email = 'Email is required'; } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) { errors.email = 'Invalid email address'; }
            if (!values.phone) { errors.phone = 'Mobile number is required'; } else if (values.phone.length !== 10) { errors.phone = 'Enter valid Mobile number!'; } else if (!/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i.test(values.phone)) { errors.phone = 'Please enter numbers format only'; }
            if (!values.country || values.country === '' ) { errors.country = 'Country is required'; }
            if (!values.state) { errors.state = 'State is required'; }
            if (!values.district) { errors.district = 'District is required'; }
            if (!values.city) { errors.city = 'City is required'; }
            if (!values.pincode) { errors.pincode = 'Pin code is required'; } else if (!/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i.test(values.pincode)) { errors.pincode = 'Please enter numbers format only'; }
            if (!values.street) { errors.street = 'Address is required'; }
            if(typeof dynamicInputs !== "undefined"){ for (const [key] of Object.entries(dynamicInputs)) { if (!values[key]) { errors[key] = `${key} is required`; } } }
            return errors;
        },
        onSubmit: (values) => {
            const formData = new FormData();
            formData.append("first_name", values.first_name)
            formData.append("last_name", values.last_name)
            formData.append("email", values.email)
            formData.append("phone", values.phone)
            formData.append("country", values.country)
            formData.append("state", values.state)
            formData.append("district", values.district)
            formData.append("city", values.city)
            formData.append("pincode", values.pincode)
            formData.append("street", values.street)

            var requirements = {};
            if(typeof dynamicInputs !== "undefined"){
                for(const [key] of Object.entries(dynamicInputs)){ requirements[key] = values[key]; }
            }
            formData.append("requirements", JSON.stringify(requirements))

            axios.post(`${API_URL}atum/dealers/subscription/create`, formData)
            .then(response => {
                localStorage.setItem('dealerSubscriptionErrorMsg', response.data.message);
                response.data.status === 200 ? navigate('/success') : navigate('/error')
                formik.resetForm();
            }).catch(err => console.log(err))
        }
    }); 

    useEffect(() => {
        fetchStateDistrictInfo();
    }, []);

    return (
        <>
            <form onSubmit={formik.handleSubmit} className="m-0 p-0" autoComplete="off">
                <div className="row">
                    <h4 className="primary-text text-capitalize fs-6 fw-bold m-0 px2 py-1">Personal Information</h4>
                    <div className="col-12 col-sm-12 col-md-4 col-lg-4 py-2 px-2">
                        <input type="text" className="form-control" name="first_name" placeholder="First Name*" onChange={formik.handleChange} value={formik.values.first_name} />
                        {formik.errors.first_name ? <span className="text-danger fs-8">{formik.errors.first_name}</span> : null}
                    </div>
                    <div className="col-12 col-sm-12 col-md-4 col-lg-4 py-2 px-2">
                        <input type="text" className="form-control" name="last_name" placeholder="Last Name*" onChange={formik.handleChange} value={formik.values.last_name} />
                        {formik.errors.last_name ? <span className="text-danger fs-8">{formik.errors.last_name}</span> : null}
                    </div>
                    <div className="col-12 col-sm-12 col-md-4 col-lg-4 py-2 px-2">
                        <input type="text" className="form-control" name="email" placeholder="Email*" onChange={formik.handleChange} value={formik.values.email} />
                        {formik.errors.email ? <span className="text-danger fs-8">{formik.errors.email}</span> : null}
                    </div>
                    <MobileVerificationComp setBecomeDealerSubmitBtnDisabled={setBecomeDealerSubmitBtnDisabled} mobileChangeEvent={formik.handleChange} formik={formik} />
                </div>
                <div className="row mt-4">
                    <h4 className="primary-text text-capitalize fs-6 fw-bold m-0 px2 py-1">Address Information</h4>
                    <div className="col-12 col-sm-12 col-md-4 col-lg-4 py-2 px-2">
                        <select className="form-select" name="country" onChange={formik.handleChange} value={formik.values.country}>
                            <option value="India">India</option>
                        </select>
                        {formik.errors.country ? <span className="text-danger fs-8">{formik.errors.country}</span> : null}
                    </div>
                    <div className="col-12 col-sm-12 col-md-4 col-lg-4 py-2 px-2">
                        <select className="form-select" name="state" onChange={(e) => { formik.handleChange(e); handleStateDistrict(e.target.value); }} value={formik.values.state}>
                            <option defaultValue>State</option>
                            {stDst && Array.from(new Set(stDst.map(obj => obj.State))).map((State, index) => {
                                return (<option value={State} key={index}>{State}</option>)
                            })}
                        </select>
                        {formik.errors.state ? <span className="text-danger fs-8">{formik.errors.state}</span> : null}
                    </div>
                    <div className="col-12 col-sm-12 col-md-4 col-lg-4 py-2 px-2">
                        <select className="form-select" name="district" onChange={formik.handleChange} value={formik.values.district}>
                            <option defaultValue>District</option>
                            {distData && distData.map((distObj, index) => {
                                return (<option value={distObj.District} key={index}>{distObj.District}</option>)
                            })}
                        </select>
                        {formik.errors.district ? <span className="text-danger fs-8">{formik.errors.district}</span> : null}
                    </div>
                    <div className="col-12 col-sm-12 col-md-4 col-lg-4 py-2 px-2">
                        <input type="text" className="form-control" name="city" placeholder="City" onChange={formik.handleChange} value={formik.values.city} />
                        {formik.errors.city ? <span className="text-danger fs-8">{formik.errors.city}</span> : null}
                    </div>
                    <div className="col-12 col-sm-12 col-md-4 col-lg-4 py-2 px-2">
                        <input type="text" className="form-control" name="pincode" maxLength="6" placeholder="Pin Code" onChange={formik.handleChange} value={formik.values.pincode} />
                        {formik.errors.pincode ? <span className="text-danger fs-8">{formik.errors.pincode}</span> : null}
                    </div>
                    <div className="col-12 col-sm-12 col-md-4 col-lg-4 py-2 px-2">
                        <input type="text" className="form-control" name="street" placeholder="Street / Area / Locality" onChange={formik.handleChange} value={formik.values.street} />
                        {formik.errors.street ? <span className="text-danger fs-8">{formik.errors.street}</span> : null}
                    </div>
                </div>
                <div className="row mt-4">
                    <h4 className="primary-text text-capitalize fs-6 fw-bold m-0 px2 py-1">Other Information</h4>
                    {dynamicInputs && Object.entries(dynamicInputs).map(([key, field]) => {
                        return (
                            <div key={key} className="col-12 col-sm-12 col-md-6 col-lg-6 py-2 px-2">
                                { field.dsri_input_type === 'select' ? (
                                    <>
                                        <select className="form-select" name={key} onChange={formik.handleChange} value={formik.values[key]}>
                                            <option defaultValue>{field.dsri_label_name}</option>
                                            {field.options.map((option, idx) => ( <option key={idx} value={option}>{option}</option> ))}
                                        </select>
                                        {formik.errors[key] && (<span className="text-danger fs-8">{formik.errors[key]}</span>)}
                                    </>
                                ) : (
                                    <>
                                        <input type="text" className="form-control" name={key} placeholder={field.dsri_label_name} onChange={formik.handleChange} value={formik.values[key]} />
                                        {formik.errors[key] && (<span className="text-danger fs-8">{formik.errors[key]}</span>)}
                                    </>
                                )}
                            </div>
                        );
                    })}
                </div>
                <div className="row mt-4">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 py-2 px-2 d-flex justify-content-center">
                        <input type="submit" value="Submit" className="btn btn-danger fw-semibold global-primary-bg px-3" disabled={becomeDealerSubmitBtnDisabled} />
                    </div>
                </div>
            </form>
        </>
    )
}

export default BecomePartnerDynamicForm