import React, { useEffect, useRef, useState } from 'react';
import { InsideBannerList } from './Innerbanner';
import Header from '../common/Header';
import Footer from '../common/Footer';
import solarCalculatorJson from '../../../solar-calculator.json';
import CircularSlider from '@fseehawer/react-circular-slider';

const SolarCalculator = () => {
    
    // INSIDE BANNER START
    const [state, setState] = useState({});
    useEffect(() => {
        let solarCalculatorInsideSlider = InsideBannerList.filter((item) => item.title === 'solar-calculator');
        setState(solarCalculatorInsideSlider[0]);
    }, []);
    // INSIDE BANNER END

    const solarCalcProductProps = solarCalculatorJson['product-props'];
    const solarCalcInputProps = solarCalculatorJson['calculator-props'];
    const initialValues = {
        inputs: {
            electricityBill: 0,
            unitsConsumedPerMonth: 0,
            rooftopArea: 0,
            noOfyears: solarCalcInputProps["default-years"]-1,
        },
        output: {
            systemSize: 0,
            systemPrice: { withStructure: 0, withOutStructure: 0 },
            totalSavings: { withStructure: 0, withOutStructure: 0 },
            outputPerMonth: 0
        }
    };
    const [calcStatus, setCalcStatus] = useState(false);

    const calcIOs = useRef(initialValues);
    const product = useRef(solarCalcProductProps[0]);
    const productType = useRef(product.current['Product Types'][0]);

    const productOptionsUi = useRef(solarCalcProductProps.map((p) => ( <option key={p['Product Title'].replaceAll(' ','')} value={p['Product Title']}>{p['Product Title']}</option> )));
    const productTypeUi = useRef(product.current['Product Types'].map((pt, ptIndex) => (
        <div key={pt.info.name.replaceAll(' ','')} className={`col-6 d-flex flex-column align-items-center justify-content-center`} onClick={() => { runCalculation('productType', productType, pt.info.name); }}>
            <div className={`w-100 d-flex justify-content-center align-items-center rounded propertytype-block ${ptIndex === 0 && 'active'}`}>
                <div style={{ fontSize: '2em' }}>{pt.info.icon}</div>
            </div>
            <p className="fs-6 fw-semibold mt-2 mb-0">{pt.info.name}</p>
        </div>
    )));

    const runCalculation = (actionType, actionOn, actionValue) => {
        if(actionType === 'product'){ product.current = solarCalcProductProps.find((p) => { return p['Product Title'] === actionValue }); calcIOs.current = initialValues; }
        if(actionType === 'product' || actionType === 'productType'){ 
            productType.current = (actionType === 'productType') ? product.current['Product Types'].find(pt => pt.info.name === actionValue) : product.current['Product Types'][0];
            productTypeUi.current = product.current['Product Types'].map(pt => (
                <div key={pt.info.name.replaceAll(' ','')} className={`col-6 d-flex flex-column align-items-center justify-content-center`} onClick={() => { runCalculation('productType', productType, pt.info.name); }}>
                    <div className={`w-100 d-flex justify-content-center align-items-center rounded propertytype-block ${pt.info.name === productType.current.info.name && 'active'}`}>
                        <div style={{ fontSize: '2em' }}>{pt.info.icon}</div>
                    </div>
                    <p className="fs-6 fw-semibold mt-2 mb-0">{pt.info.name}</p>
                </div>
            ));
        }else{ calcIOs.current.inputs[actionOn] = (typeof actionValue !== 'undefined' && actionValue !== "" && !isNaN(actionValue)) ? parseInt(actionValue) : initialValues.inputs[actionOn]; }

        if(Object.keys(calcIOs.current.inputs).filter(a => calcIOs.current.inputs[a] <= 0).length <= 0){
            var areaRequiredPerKW = productType.current["Area Required Per KW"];
            var kwUnitsGenerationPerDay = productType.current["1 KW Units Generation per Day"];
            var projectCostWithStructure = productType.current["Project Cost"]["With Structure"];
            var projectCostWithoutStructure = productType.current["Project Cost"]["Without Structure"];

            var ratePerUnit = calcIOs.current.inputs.electricityBill/calcIOs.current.inputs.unitsConsumedPerMonth;
            var systemSizeArea = calcIOs.current.inputs.rooftopArea/areaRequiredPerKW;
            var systemSizeUnit = calcIOs.current.inputs.unitsConsumedPerMonth/(kwUnitsGenerationPerDay*30);

            calcIOs.current.output.systemSize = Math.min(systemSizeArea, systemSizeUnit);
            calcIOs.current.output.outputPerMonth = calcIOs.current.output.systemSize*kwUnitsGenerationPerDay*30;

            calcIOs.current.output.systemPrice.withStructure = projectCostWithStructure*calcIOs.current.output.outputPerMonth*calcIOs.current.output.systemSize;
            calcIOs.current.output.systemPrice.withOutStructure = projectCostWithoutStructure*calcIOs.current.output.outputPerMonth*calcIOs.current.output.systemSize;

            calcIOs.current.output.totalSavings.withStructure = calcIOs.current.inputs.unitsConsumedPerMonth*calcIOs.current.inputs.noOfyears*12*ratePerUnit-calcIOs.current.output.systemPrice.withStructure;
            calcIOs.current.output.totalSavings.withOutStructure = calcIOs.current.inputs.unitsConsumedPerMonth*calcIOs.current.inputs.noOfyears*12*ratePerUnit-calcIOs.current.output.systemPrice.withOutStructure;
        }else{ calcIOs.current.output = initialValues.output; }
        
        setCalcStatus((oldStatus) => { if(oldStatus === true){ console.log(calcStatus); return false }else{ return true } });
    }
    
    return (
        <>
            <Header/>
                <section className="inner-banner" style={{ backgroundImage: `url(${state.image})` }} >
                    <div className="container">
                        <div className="row">
                            <h2 className="text-white text-uppearcase inner-banner-title fs-1 fw-semibold text-center position-absolute top-50 start-50 translate-middle">{state.heading}</h2>
                        </div>
                    </div>
                </section>
                <section className="calculator_div">
                    <div className="container section-title text-center">
                        <h2 className="section_title">Use our solar calculator <br className="hidden-xs" /> <span className="small_section_title"> find out how much money you can save with rooftop solar</span> </h2>
                        <div id="scrollToCalli" className="hidden-xs"></div>
                    </div>
                    <div className="container cal_top_space">
                        <div className="row">
                            <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 m-0 p-0 left_side_div_border">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 left_side_div">
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 field_div">
                                        <div className="row m-0 p-0 justify-content-center">
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-5">
                                                <h3 className="calculate_field_title pb-2">Choose Product <sup>*</sup></h3>
                                                <select className="form-select" onChange={(t) => { runCalculation('product', product, t.target.value); }}>{productOptionsUi.current}</select>
                                            </div>

                                            <h3 className="calculate_field_title pb-2">Property type <sup>*</sup></h3>
                                            {productTypeUi.current}
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 field_div tab_spacing">
                                        <div className="row m-0 p-0">
                                            <h3 className="calculate_field_title pb-2">Electricity Bill (Rs.) <sup>*</sup></h3>
                                            <div className="col-12">
                                                <div className="slider_area">
                                                    <div className="w-100">
                                                        <div className="d-flex justify-content-between slider_div">
                                                            <span className="pe-2">0</span>
                                                            <input type="range" className="w-100 custom-range" id="electricityBill" min={solarCalcInputProps['electricity-bill'].min} max={solarCalcInputProps['electricity-bill'].max} step="1" value={calcIOs.current.inputs.electricityBill} onChange={(a) => { runCalculation('calculate', 'electricityBill', parseInt(a.target.value)) }} />
                                                            <span className="ps-2">5L</span>
                                                        </div>
                                                        <div className="d-flex flex-column flex-md-row justify-content-start align-items-center slider_input_div">
                                                            <span className="before_input">Rs.</span>
                                                            <input type="number" className="form-control22 get_electricityBill ms-2 me-2" min={solarCalcInputProps['electricity-bill'].min} max={solarCalcInputProps['electricity-bill'].max} value={calcIOs.current.inputs.electricityBill} onChange={(a) => { if(parseInt(a.target.value) <= solarCalcInputProps['electricity-bill'].max){ runCalculation('calculate', 'electricityBill', parseInt(a.target.value)) } }} />
                                                            <span className="after_input">Per Month</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 field_div tab_spacing">
                                        <div className="row m-0 p-0">
                                            <h3 className="calculate_field_title pb-2">Avg. No of Units consumed per month <sup>*</sup></h3>
                                            <div className="col-12">
                                                <div className="slider_area">
                                                    <div className="w-100">
                                                        <div className="d-flex justify-content-between slider_div">
                                                            <span className="pe-2">0</span>
                                                            <input type="range" className="w-100 custom-range" id="electricityBill" min={solarCalcInputProps['units-consumed'].min} max={solarCalcInputProps['units-consumed'].max} step="1" value={calcIOs.current.inputs.unitsConsumedPerMonth} onChange={(a) => { runCalculation('calculate', 'unitsConsumedPerMonth', parseInt(a.target.value)) }} />
                                                            <span className="ps-2">4L</span>
                                                        </div>
                                                        <div className="d-flex flex-column flex-md-row justify-content-start align-items-center slider_input_div">
                                                            <input type="number" className="form-control22 get_electricityBill ms-2 me-2" min={solarCalcInputProps['units-consumed'].min} max={solarCalcInputProps['units-consumed'].max} value={calcIOs.current.inputs.unitsConsumedPerMonth} onChange={(a) => { if(parseInt(a.target.value) <= solarCalcInputProps['units-consumed'].max){ runCalculation('calculate', 'unitsConsumedPerMonth', parseInt(a.target.value)) } }} />
                                                            <span className="after_input">Units Per Month</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 field_div tab_spacing">
                                        <div className="row m-0 p-0">
                                            <h3 className="calculate_field_title pb-2">Rooftop Area (sq. ft.) <sup>*</sup></h3>
                                            <div className="col-12">
                                                <div className="slider_area">
                                                    <div className="w-100">
                                                        <div className="d-flex justify-content-between slider_div">
                                                            <span className="pe-2">0</span>
                                                            <input type="range" className="w-100 custom-range" id="electricityBill" min={solarCalcInputProps['roottop-area'].min} max={solarCalcInputProps['roottop-area'].max} step="1" value={calcIOs.current.inputs.rooftopArea} onChange={(a) => { runCalculation('calculate', 'rooftopArea', parseInt(a.target.value)) }} />
                                                            <span className="ps-2">3L</span>
                                                        </div>
                                                        <div className="d-flex flex-column flex-md-row justify-content-start align-items-center slider_input_div">
                                                            <input type="number" className="form-control22 get_electricityBill ms-2 me-2" min={solarCalcInputProps['roottop-area'].min} max={solarCalcInputProps['roottop-area'].max} value={calcIOs.current.inputs.rooftopArea} onChange={(a) => { if(parseInt(a.target.value) <= solarCalcInputProps['roottop-area'].max){ runCalculation('calculate', 'rooftopArea', parseInt(a.target.value)) } }} />
                                                            <span className="after_input">Sq. Ft.</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12 right_side_div d-flex align-items-center justify-content-center">
                                <div className="row space_left_side d-flex align-items-center justify-content-center mx-auto cal-right-section-width">
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <div className="w-100 d-flex justify-content-center align-items-center pb-5">
                                            <CircularSlider
                                                dataIndex={solarCalcInputProps["default-years"]-1}
                                                width={280}
                                                valueFontSize="6rem"
                                                valueFontColor="red"
                                                label="Years"
                                                labelColor="#000"
                                                knobColor="#fff"
                                                progressColorFrom="rgb(237, 28, 36)"
                                                progressColorTo="rgb(237, 28, 36)"
                                                progressSize={25}
                                                trackColor="#eeeeee"
                                                trackSize={25}
                                                min={1}
                                                max={25}
                                                onChange={ (value) => { runCalculation('calculate', 'noOfyears', parseInt(value)) } }
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-12">
                                        <div className="w-100 cal-box">
                                            <div className="w-100 d-flex align-items-center justify-content-center mb-3"><h3 className="m-0 p-0 text-uppercase fs-5 fw-bold primary-text">Total Savings</h3></div>
                                            <div className="d-flex flex-column flex-md-row flex-lg-row flex-xl-row justify-content-between align-items-center">
                                                <div className="d-flex flex-column justify-content-center align-items-center">
                                                    <div className="cal-title">With Structure</div>
                                                    <div className="cal-value">&nbsp;&#8377;{calcIOs.current.output.totalSavings.withStructure.toFixed(2)}</div>
                                                </div>
                                                <div className="d-flex flex-column justify-content-center align-items-center mobile-top-space">
                                                    <div className="cal-title">Without Structure</div>
                                                    <div className="cal-value">&nbsp;&#8377;{calcIOs.current.output.totalSavings.withOutStructure.toFixed(2)}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-100 cal-box mt-3">
                                            <div className="w-100 d-flex align-items-center justify-content-center mb-3"><h3 className="m-0 p-0 text-uppercase fs-5 fw-bold primary-text">System Price</h3></div>
                                            <div className="d-flex flex-column flex-md-row flex-lg-row flex-xl-row justify-content-between align-items-center">
                                                <div className="d-flex flex-column justify-content-center align-items-center">
                                                    <div className="cal-title">With Structure</div>
                                                    <div className="cal-value">&nbsp;&#8377;{calcIOs.current.output.systemPrice.withStructure.toFixed(2)}</div>
                                                </div>
                                                <div className="d-flex flex-column justify-content-center align-items-center mobile-top-space">
                                                    <div className="cal-title">Without Structure</div>
                                                    <div className="cal-value">&nbsp;&#8377;{calcIOs.current.output.systemPrice.withOutStructure.toFixed(2)}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6">
                                        <div className="w-100 cal-box mt-3">
                                            <div className="d-flex justify-content-center align-items-center">
                                                <div className="d-flex flex-column justify-content-center align-items-center">
                                                    <div className="cal-title text-center">System Size</div>
                                                    <div className="cal-value text-center">&nbsp;{calcIOs.current.output.systemSize.toFixed(2)} kwp</div>
                                                </div>
                                            </div>
                                        </div>     
                                    </div>
                                    <div className="col-sm-12 col-md-6">
                                        <div className="w-100 cal-box mt-3">
                                            <div className="d-flex justify-content-center align-items-center">
                                                <div className="d-flex flex-column justify-content-center align-items-center">
                                                    <div className="cal-title text-center">Output/per month</div>
                                                    <div className="cal-value text-center">&nbsp;{calcIOs.current.output.outputPerMonth.toFixed(2)} units</div>
                                                </div>
                                            </div>
                                        </div>     
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            <Footer/>
        </>
    )
}

export default SolarCalculator