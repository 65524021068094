import React from 'react';
import Header from './components/website/common/Header';
import Footer from './components/website/common/Footer';
import { Link } from 'react-router-dom';

export const ErrorPage = (props) => {
  return (
    <div>
        <Header/>
            <section className="py-5">
                <div className="container py-4 my-5">
                    <div className="row">
                        <div className="col-12 text-center py-4 my-5 mx-auto mw-400px">
                            <h1 className="primary-text text-capitalize all-heading-text m-0 p-0 fs-1 fw-bolder"><b>{props.title}</b></h1>
                            <p className="text-muted m-0 p-0">{props.desc}</p>
                            <Link to={props.path} className="btn btn-danger rounded-2 mt-3">{props.pathName}</Link>
                        </div>
                    </div>
                </div>
            </section>
        <Footer/>
    </div>
  )
}
