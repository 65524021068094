import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { CDN_URL } from '../../../Config';
import products from '../../../products.json';
import SolarCalBtn from './SolarCalBtn';

const Header = () => {
    const [isHovered, setIsHovered] = useState(false);

    const productKeys = Object.keys(products);
    const productTitles = Object.keys(products).map((p) => { return products[p].title; });
    const productPages = Object.keys(products).map((p) => { return products[p].product_page_name; });
    const [isActive] = useState();
    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark m-0 p-0 py-2 ">
                <div className="container">
                    <div className="w-100 d-flex justify-content-between align-items-center flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row">
                        <ul className="navbar-nav mr-auto">
                            <li className="nav-item d-flex justify-content-center align-items-center">
                                <span className="text-white">
                                    <Link  to="/solar-calculator" className="text-white fs-6 fw-bold topnav-textshadow d-flex justify-content-center align-items-center">
                                        <button type="button" className={`d-flex justify-content-center align-items-center text-center me-1 solarCal-btn ${!isHovered ? 'blinking-button' : ''}`} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                                            <svg xmlns="http://www.w3.org/2000/svg"  width="13" height="13" fill="red" viewBox="0 0 448 512"><path d="M400 0H48C22.4 0 0 22.4 0 48v416c0 25.6 22.4 48 48 48h352c25.6 0 48-22.4 48-48V48c0-25.6-22.4-48-48-48zM128 435.2c0 6.4-6.4 12.8-12.8 12.8H76.8c-6.4 0-12.8-6.4-12.8-12.8v-38.4c0-6.4 6.4-12.8 12.8-12.8h38.4c6.4 0 12.8 6.4 12.8 12.8v38.4zm0-128c0 6.4-6.4 12.8-12.8 12.8H76.8c-6.4 0-12.8-6.4-12.8-12.8v-38.4c0-6.4 6.4-12.8 12.8-12.8h38.4c6.4 0 12.8 6.4 12.8 12.8v38.4zm128 128c0 6.4-6.4 12.8-12.8 12.8h-38.4c-6.4 0-12.8-6.4-12.8-12.8v-38.4c0-6.4 6.4-12.8 12.8-12.8h38.4c6.4 0 12.8 6.4 12.8 12.8v38.4zm0-128c0 6.4-6.4 12.8-12.8 12.8h-38.4c-6.4 0-12.8-6.4-12.8-12.8v-38.4c0-6.4 6.4-12.8 12.8-12.8h38.4c6.4 0 12.8 6.4 12.8 12.8v38.4zm128 128c0 6.4-6.4 12.8-12.8 12.8h-38.4c-6.4 0-12.8-6.4-12.8-12.8V268.8c0-6.4 6.4-12.8 12.8-12.8h38.4c6.4 0 12.8 6.4 12.8 12.8v166.4zm0-256c0 6.4-6.4 12.8-12.8 12.8H76.8c-6.4 0-12.8-6.4-12.8-12.8V76.8C64 70.4 70.4 64 76.8 64h294.4c6.4 0 12.8 6.4 12.8 12.8v102.4z"/></svg>
                                        </button>
                                        SOLAR CALCULATOR
                                    </Link>
                                </span>
                            </li>
                        </ul>
                        <ul className="navbar-nav mr-auto">
                            <li className="nav-item d-flex justify-content-center align-items-center">
                                <a className="nav-link22 text-white fs-7 topnav-textshadow" href="tel:9100682886"><svg xmlns="http://www.w3.org/2000/svg" width="18" fill="red" className="pe-1" viewBox="0 0 512 512"><path d="M497.4 361.8l-112-48a24 24 0 0 0 -28 6.9l-49.6 60.6A370.7 370.7 0 0 1 130.6 204.1l60.6-49.6a23.9 23.9 0 0 0 6.9-28l-48-112A24.2 24.2 0 0 0 122.6 .6l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.3 24.3 0 0 0 -14-27.6z"/></svg>9100682886</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <div id="sticky-header">
                <section  className="w-100 main-menu bg-white">
                    <div className="container">
                        <div className="row">
                            <nav className="navbar navbar-expand-lg navbar-light website-menu-acive-line py-0">
                                <div className="container-fluid">
                                    <Link className="navbar-brand" to="/">
                                        <div className="nav-logo-svg d-flex justify-content-between mx-auto">
                                            <img src={CDN_URL+'visaka/atum/website/assets/images/darklogo.png'} className="atum-logo" alt="atum-logo" />
                                        </div>
                                    </Link>
                                    <button className="navbar-toggler my-1" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                        <span className="navbar-toggler-icon"></span>
                                    </button>
                                    <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
                                        <ul className="navbar-nav mb-lg-0 my-4 my-lg-0 my-sm-4">
                                            <li className="nav-item">
                                                <NavLink className="nav-link text-uppercase py-lg-4 py-md-4 py-3 px-2 lh-1" to="/" style={{isActive}}>Home</NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink className="nav-link text-uppercase py-lg-4 py-md-4 py-3 px-2 lh-1 about" to="/about" style={{isActive}}>About Us</NavLink>
                                            </li>
                                            <li className="nav-item dropdown">
                                                <div className="nav-link text-uppercase py-lg-4 py-md-4 py-3 px-2 lh-1 technicalspec dropdown-toggle" role="button" data-toggle="dropdown" aria-expanded="false">Products</div>
                                                <div className="dropdown-menu">
                                                    {productKeys.map((p, pI) => { if(productPages[pI] !== ""){ return <NavLink className="dropdown-item" to={`/products/${p}`} key={`${p}_${pI}`}>{productTitles[pI]}</NavLink> }else{ return false; } })}
                                                </div>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink className="nav-link text-uppercase py-lg-4 py-md-4 py-3 px-2 lh-1 portfolio" to="/portfolio" style={{isActive}}>Portfolio</NavLink>
                                            </li>
                                            <li className="nav-item dropdown">
                                                <div className="nav-link text-uppercase py-lg-4 py-md-4 py-3 px-2 lh-1 technicalspec dropdown-toggle" role="button" data-toggle="dropdown" aria-expanded="false">Technical Specs</div>
                                                <div className="dropdown-menu">
                                                    {productKeys.map((p, pI) => { return <NavLink className="dropdown-item" to={`/technical-specs/${p}`} key={`${p}_${pI}`}>{productTitles[pI]}</NavLink>})}
                                                </div>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink className="nav-link text-uppercase py-lg-4 py-md-4 py-3 px-2 lh-1 news" to="/news" style={{isActive}}>News & Events</NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink className="nav-link text-uppercase py-lg-4 py-md-4 py-3 px-2 lh-1 blog" to="/blog" style={{isActive}}>Blog</NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink className="nav-link text-uppercase py-lg-4 py-md-4 py-3 px-2 lh-1 contact" to="/contact" style={{isActive}}>Contact Us</NavLink>
                                            </li>
                                            {/* <li className="nav-item">
                                                <NavLink className="nav-link text-uppercase py-lg-4 py-md-4 py-3 px-2 lh-1 solar-calculator" to="/solar-calculator" style={{isActive}}>Solar Calcolator</NavLink>
                                            </li> */}
                                        </ul>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>
                </section>
            </div>
            <SolarCalBtn/>
        </>
    )
}

export default Header