import React from 'react';
import { Link } from 'react-router-dom';

const Success = () => {
  return (
    <div>
      <div className='successForm'>
          <div className='position-absolute top-50 start-50 translate-middle text-center shadow p-5 rounded'>
              <div className='icon mb-4'>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="80px" fill="green"><path d="M256 32a224 224 0 1 1 0 448 224 224 0 1 1 0-448zm0 480A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM363.3 203.3c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L224 297.4l-52.7-52.7c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6l64 64c6.2 6.2 16.4 6.2 22.6 0l128-128z"/></svg>
              </div>
              <div className='details'>
                  <h2 className='text-dark fs-2 fw-bold'>Success</h2>
                  <p className='fs-7 text-gray'>Thank you for showing the interest. <br/> Our Team will connect with you.</p>
              </div>
              <div className='goToHomeBtn rounded  py-2 px-3'>
                  <Link to='/' className='btn btn-danger fw-semibold global-primary-bg m-0 p-0 px-3 py-2 white-text'>Go To Home Page</Link>
              </div>
          </div>
      </div>
    </div>
  )
}

export default Success