import { useState, useEffect }  from 'react';
import { useLocation } from "react-router-dom";

const PageResetComp = () => {
    const [fadeOutStatus, setFadeOutStatus] = useState(false);
    const { pathname } = useLocation();
    
    useEffect(() => {
        window.scrollTo(0, 0);
        setFadeOutStatus(false)
    }, [pathname]);

    setTimeout(function () { setFadeOutStatus((e)=>{ return true; }); }, 2000);

    return (
        <div className={`loading_eff ${((fadeOutStatus === true) ? ' loading_eff_fade' : '')} position-fixed top-0 bottom-0 w-100 h-100 bg-light align-items-center justify-content-center`}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><circle fill="none" stroke="#FF156D" cx="100" cy="100" r="0"><animate attributeName="r" calcMode="spline" dur="2" values="1;80" keyTimes="0;1" keySplines="0 .2 .5 1" repeatCount="indefinite"></animate><animate attributeName="stroke-width" calcMode="spline" dur="2" values="0;25" keyTimes="0;1" keySplines="0 .2 .5 1" repeatCount="indefinite"></animate><animate attributeName="stroke-opacity" calcMode="spline" dur="2" values="1;0" keyTimes="0;1" keySplines="0 .2 .5 1" repeatCount="indefinite"></animate></circle></svg>
        </div>
    )
}

export default PageResetComp