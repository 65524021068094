import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../../../Config';
import { CDN_URL } from '../../../Config';

const EnquireNowForm = ({dynamicInputs, customInitialValues, campaignId}) => {

    const [stDst, setstDst] = useState();
    const [distData, setDistData] = useState();
    const navigate = useNavigate();

    const fetchStateDistrictInfo = async () => {
        const stateDistrictRes = await fetch(CDN_URL+"visaka/common/json/indianStatesCities.json");
        const stateDistrictResData = await stateDistrictRes.json();
        setstDst(stateDistrictResData);
    }

    const handleStateDistrict = (e) => {
        const getDistrict = stDst.filter((distitem) => distitem.State === e);
        setDistData(getDistrict);
    }

    useEffect(() => { fetchStateDistrictInfo(); }, []);
    
    const formik = useFormik({
        initialValues: customInitialValues,

        validate: (values) => {
            let errors = {};
            if (!values.name) { errors.name = 'Name is required'; }
            if (!values.email) { errors.email = 'Email is required'; } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) { errors.email = 'Invalid email address'; }
            if (!values.phone) { errors.phone = 'Mobile number is required'; } else if ( !/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i.test(values.phone)) { errors.phone = 'Please enter numbers format only'; }
            if (!values.state) { errors.state = 'State is required'; }
            if (values.state){ handleStateDistrict(values.state) }
            if (!values.district) { errors.district = 'District is required'; }
            if (!values.city) { errors.city = 'City is required'; }
            if (!values.pincode) { errors.pincode = 'Pin code required'; } else if ( !/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i.test(values.pincode)) { errors.pincode = 'Please enter numbers format only'; }

            for(const [key] of Object.entries(dynamicInputs)){ if (!values[key.toLowerCase().split(' ').join('')]) { errors[key.toLowerCase().split(' ').join('')] = key+' is required'; } }
            return errors;
        },

        onSubmit:(values) => {
            const formData = new FormData();
            formData.append("name", values.name)
            formData.append("email", values.email)
            formData.append("phone_number", values.phone)
            formData.append("state", values.state)
            formData.append("district", values.district)
            formData.append("city", values.city)
            formData.append("pincode", values.pincode)
            formData.append("category", "Item enquiry")
            formData.append("campaign_id", campaignId)

            var enquiry_requirements = {};
            for(const [key] of Object.entries(dynamicInputs)){ enquiry_requirements[key] = values[key.toLowerCase().split(' ').join('')]; }
            formData.append("enquiry_requirements", JSON.stringify(enquiry_requirements))

            axios.post(`${API_URL}atum/enquiries/create`, formData)
            .then(response=>
                {
                    response.data.status === 200 ? navigate('/success') : navigate('/error')
                    formik.resetForm();
                }
            ).catch( err => console.log(err) )
        }
    });

    var formDynamicUi = [];
    for(const [key, value] of Object.entries(dynamicInputs)){
        if(Object.values(value).length > 0){
            formDynamicUi.push(<div className="col-12 col-sm-12 col-md-6 col-lg-6 py-2 px-1 atc_select" key={`dynamic_${key.toLowerCase().split(' ').join('')}`}>
                <select className="form-select" name={key.toLowerCase().split(' ').join('')} onChange={formik.handleChange} value={formik.values[key.toLowerCase().split(' ').join('')]}>
                    <option value="" defaultValue>{`Select ${key}`}</option>
                    {Object.values(value).map(e  => { return <option value={e} key={e}>{e}</option> })}
                </select>
                {formik.errors[key.toLowerCase().split(' ').join('')] ? <span className="text-danger fs-8">{formik.errors[key.toLowerCase().split(' ').join('')]}</span> : null}
            </div>);
        }else{
            formDynamicUi.push(<div className="col-12 col-sm-12 col-md-6 col-lg-6 py-2 px-1" key={`dynamic_${key.toLowerCase().split(' ').join('')}`}>
                <input className="form-control" type="text" name={`${key.toLowerCase().split(' ').join('')}`} placeholder={`Enter ${key}`} onChange={formik.handleChange} value={formik.values[key.toLowerCase().split(' ').join('')]} />
                {formik.errors[key.toLowerCase().split(' ').join('')] ? <span className="text-danger fs-8">{formik.errors[key.toLowerCase().split(' ').join('')]}</span> : null}
            </div>);
        }
    }

    return (
        <div>
            <section className="bg-white22 py-1">
                <div className="container m-0 p-0">
                    <div className="row m-0 p-0">
                        <form onSubmit={formik.handleSubmit} className="m-0 p-0">
                            <div className="row m-0 p-0">
                                <div className="col-12 col-sm-12 col-md-6 col-lg-6 py-2 px-1">
                                    <input className="form-control" type="text" name="name" placeholder="Enter Name*" onChange={formik.handleChange} value={formik.values.name} />
                                    {formik.errors.name ? <span className="text-danger fs-8">{formik.errors.name}</span> : null}
                                </div>
                                <div className="col-12 col-sm-12 col-md-6 col-lg-6 py-2 px-1">
                                    <input className="form-control" type="text" name="email" placeholder="Enter Email*" onChange={formik.handleChange} value={formik.values.email} />
                                    {formik.errors.email ? <span className="text-danger fs-8">{formik.errors.email}</span> : null}
                                </div>
                                <div className="col-12 col-sm-12 col-md-6 col-lg-6 py-2 px-1">
                                    <input className="form-control" type="text" name="phone" placeholder="Enter Phone No.*" maxLength={10} onChange={formik.handleChange} value={formik.values.phone} />
                                    {formik.errors.phone ? <span className="text-danger fs-8">{formik.errors.phone}</span> : null}
                                </div>
                                <div className="col-12 col-sm-12 col-md-6 col-lg-6 py-2 px-1 atc_select">
                                    <select className="form-select" name="state" onChange={formik.handleChange} value={formik.values.state}>
                                        <option defaultValue>Select State*</option>
                                        {stDst && Array.from(new Set(stDst.map(obj => obj.State))).map((State, index) => {
                                            return (<option value={State} key={index}>{State}</option>)
                                        })}
                                    </select>
                                    {formik.errors.state ? <span className="text-danger fs-8">{formik.errors.state}</span> : null}
                                </div>
                                <div className="col-12 col-sm-12 col-md-6 col-lg-6 py-2 px-1 atc_select">
                                    <select className="form-select" name="district" onChange={formik.handleChange} value={formik.values.district}>
                                        <option defaultValue>Select District*</option>
                                        {distData && distData.map((distObj, index) => {
                                            return (
                                                <option value={distObj.District} key={index}>{distObj.District}</option>
                                            )
                                        })}
                                    </select>
                                    {formik.errors.district ? <span className="text-danger fs-8">{formik.errors.district}</span> : null}
                                </div>
                                <div className="col-12 col-sm-12 col-md-6 col-lg-6 py-2 px-1">
                                    <input className="form-control" type="text" name="city" placeholder="City*" onChange={formik.handleChange} value={formik.values.city} />
                                    {formik.errors.city ? <span className="text-danger fs-8">{formik.errors.city}</span> : null}
                                </div>
                                <div className="col-12 col-sm-12 col-md-6 col-lg-6 py-2 px-1">
                                    <input className="form-control" type="text" name="pincode" placeholder="Enter Pincode*" maxLength={6} onChange={formik.handleChange} value={formik.values.pincode} />
                                    {formik.errors.pincode ? <span className="text-danger fs-8">{formik.errors.pincode}</span> : null}
                                </div>
                                {formDynamicUi.map(a => { return a })}
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 py-2 px-1 d-flex justify-content-center">
                                    <input type="submit" value="Submit" className="btn btn-danger fw-semibold global-primary-bg px-3" />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default EnquireNowForm