import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../../common/Header';
import Footer from '../../common/Footer';
import { InsideBannerList } from '../Innerbanner';
import { API_URL } from '../../../../Config';
import BecomePartnerDynamicForm from './BecomePartnerDynamicForm';

const BecomePartner = () => {
    const [state, setState] = useState("");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [formInputs, setFormInputs] = useState(null);
    const [dynamicInputs, setDynamicInputs] = useState(null);

    useEffect(() => {
        axios.get(API_URL+'atum/dealers/subscription/requirement/inputs/list').then(res => {
            if(res.status >= 200 && res.status <= 201 && res.data.status === 200 && typeof res.data.data.response_data.subscription_requirement_input_list !== "undefined"){
                let tempInputs = {first_name: '', last_name: '', email: '', phone: '', country: 'India', state: '', district: '',  city: '', pincode: '', street: '' };
                for(const [key] of Object.entries(res.data.data.response_data.subscription_requirement_input_list)){ tempInputs[key] = ''; }
                setFormInputs(tempInputs);
                setDynamicInputs(res.data.data.response_data.subscription_requirement_input_list);
            }else{ console.error("Invalid response structure:", res); }
        }).catch(error => {
            const errorMessage = error.response ? error.response.data.message : error.message || "An error occurred. Please try again.";
            setError({ message: errorMessage });
        }).finally(a => setLoading(false));

        const becomeDealerInsideSlider = InsideBannerList.filter((item) => item.title === 'become-partner');
        setState(becomeDealerInsideSlider[0]);
    }, []);

    return (
        <>
            <Header />
            <section className="inner-banner" style={{ backgroundImage: `url(${state.image})` }} >
                <div className="container">
                    <div className="row">
                        <h2 className="text-white text-uppearcase inner-banner-title fs-1 fw-semibold text-center position-absolute top-50 start-50 translate-middle">{state.heading}</h2>
                    </div>
                </div>
            </section>
            <section className="become-dealer-form-section py-5">
                <div className="container">
                    <div className="row">
                        <div className="become-dealer-card border-0 rounded-4 shadow px-5 py-4 mx-auto d-flex align-items-center justify-content-center" style={{ minHeight: '200px' }}>
                            <div className="row">
                                {loading && ( <div className="spinner-border" role="status"><span className="visually-hidden">Loading...</span></div> )}
                                {error && ( <div className="alert alert-danger" role="alert"> {error} </div> )}
                                {formInputs !== null && <BecomePartnerDynamicForm formInputs={formInputs} dynamicInputs={dynamicInputs} />}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default BecomePartner