import React, { useEffect, useState } from 'react';
import { InsideBannerList } from './Innerbanner';
import Header from '../common/Header';
import Footer from '../common/Footer';
import { useParams } from 'react-router-dom';
import products from '../../../products.json';
import { ErrorPage } from '../../../ErrorPage';

const Products = () => {
    let { id } = useParams();
    const [state, setState] = useState({});

    useEffect(() => {
        let productInsideSlider = InsideBannerList.filter((item) => item.title === 'products');
        setState(productInsideSlider[0]);
    }, []);

    try
    {
        if (typeof products[id] === "undefined") { throw new Error("Invalid product ID passed, please try again with valid product."); }
        if (typeof products[id].title === "undefined") { throw new Error("Invalid product request found, please try again with valid product."); }
        if (typeof products[id]['technical-spects'] === "undefined") { throw new Error("Technical details not found for the product choosen, please try again."); }
        if (typeof products[id].product_page_name === "undefined") { throw new Error("Technical details not found for the product choosen, please try again."); }

        const productPageComp = require(`./${products[id].product_page_name}`);

        return (
            <div>
                <Header />
                    <section className="inner-banner" style={{ backgroundImage: `url(${state.image})` }} >
                        <div className="container">
                            <div className="row">
                                <h2 className="text-white text-uppearcase inner-banner-title fs-1 fw-semibold text-center position-absolute top-50 start-50 translate-middle">{products[id].title}</h2>
                            </div>
                        </div>
                    </section>
                    <productPageComp.default />
                <Footer />
            </div>
          );
    }catch(error){ return <ErrorPage title="404!" desc={error.message} path="/" pathName="Home" />; }
}

export default Products