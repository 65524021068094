import React from 'react'

const AtumConventionalSolarModule = () => {
  return (
    <>
        <p>AtumConventionalSolarModule</p>
    </>
  )
}

export default AtumConventionalSolarModule