import React from 'react';


const ProgressCircle = ({ noOfYears, setNoOfYears }) => {
    console.log(noOfYears);

  return (
    <div style={{ textAlign: "center" }}>
        
    </div>
  )
}

export default ProgressCircle