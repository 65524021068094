import React, { useEffect, useState } from 'react';
import { InsideBannerList } from './Innerbanner';
import { useParams } from 'react-router-dom';
import { CDN_URL } from '../../../Config';
import Header from '../common/Header';
import Footer from '../common/Footer';

const BlogDetails = () => {
    const [blogdtlIb, setblogdtlIb] = useState('');
    const { bdheading } = useParams();

    useEffect(() => {
        let arr = window.location.pathname.split('/');
        let blogInsideSlider = InsideBannerList.filter((item) => item.title === arr[2]);
        setblogdtlIb(blogInsideSlider[0]);
    }, []);

    return (
        <div>
            <Header/>
            <section className="inner-banner" style={{ backgroundImage: `url(${blogdtlIb.image})` }} >
                <div className="container">
                    <div className="row">
                        <h2 className="text-white text-uppearcase inner-banner-title fs-1 fw-semibold text-center position-absolute top-50 start-50 translate-middle">{blogdtlIb.heading}</h2>
                    </div>
                </div>
            </section>

            {bdheading === "why-atum-is-the-ultimate-roofing-solution-to-resist-extreme-weathers" &&
                <section className="blogdetails py-5">
                    <div className="container">
                        <div className="row">
                            <div className="w-75 mx-auto d-block">
                                <h2 className="primary-text text-capitalize fs-3 fw-semibold">Why ATUM is the ultimate roofing solution to resist extreme weathers</h2>
                                <p className="fs-7 text-gray">posted on: july 20, 2020 | <span className="website-span-textstyle">Author: Subha Priyadarshini</span></p>
                                <img src={CDN_URL+'visaka/atum/website/assets/images/blogdetails/1-1.jpg'} className="img-fluid mt-3 mb-4 w-100 rounded" alt="Blog Details" />
                                <p className="text-justify lh-lg m-0 p-0 pb-3">Often undervalued, choosing a sturdy roof is key to a healthy building. And with the cyclones and
                                    heavy winds impending, it’s time to brace the storm with a strong roof over your head. While we do
                                    agree that the the process of zeroing on a roof is all humdrum for a layman but quality roofing is
                                    just an informed choice away. And we say that choice is ATUM Solar Roof. Curious? Read on to know
                                    why ATUM is a safe bet for roofing that stays in place, for years together.</p>
                                <h4 className="fs-4 fw-semibold m-0 p-0 pb-2">Resilient to winds</h4>
                                <img src={CDN_URL+'visaka/atum/website/assets/images/blogdetails/1-2.jpg'} className="img-fluid mt-3 mb-4 w-100 rounded" alt="Blog Details" />
                                <p className="text-justify lh-lg m-0 p-0 pb-3">Investing in solar can be a disappointment, especially when it’s ‘gone with the wind’ every time
                                    there’s a cyclonic storm. If it is not a long-lasting, heavy duty investment, the chances of your
                                    solar fetching you returns are bleak. But that’s not the case with ATUM. Heavy winds with speeds as
                                    high as 250 kmph have no effect on ATUM Solar Roof as it is tested and certified by Lean Maestro to
                                    stand its ground unlike traditional panels that take flight with the hinge.</p>
                                <h4 className="fs-4 fw-semibold m-0 p-0 pb-2">Leak-proof Roof</h4>
                                <img src={CDN_URL+'visaka/atum/website/assets/images/blogdetails/1-3.jpg'} className="img-fluid mt-3 mb-4 w-100 rounded" alt="Blog Details" />
                                <p className="text-justify lh-lg m-0 p-0 pb-3">When we say ATUM is leak-proof, we mean it. ATUM Solar Roof has a unique, leak-proof design that
                                    prevents water from the roof to enter the building, no matter how heavy the rain. It’s an ideal
                                    choice for terrains prone to frequent hurricanes and cyclones.</p>
                                <h4 className="fs-4 fw-semibold m-0 p-0 pb-2">Powers cooling systems</h4>
                                <img src={CDN_URL+'visaka/atum/website/assets/images/blogdetails/1-4.jpg'} className="img-fluid mt-3 mb-4 w-100 rounded" alt="Blog Details" />
                                <p className="text-justify lh-lg m-0 p-0 pb-3">With the temperatures rising and clocking record highs over the past few years, cooling systems have
                                    become an inseparable part of buildings. According to the Global Status Report by the World Green
                                    Building Council, “post 2030, India will erect buildings with 3 times more floor area and demand
                                    cooling energy services than ever before”; as the reserve of fossil fuel is soon to run dry,
                                    alternative energy sources are the best to power your high power demands.</p>
                                <h4 className="fs-4 fw-semibold m-0 p-0 pb-2">Cooler interiors</h4>
                                <img src={CDN_URL+'visaka/atum/website/assets/images/blogdetails/1-5.jpg'} className="img-fluid mt-3 mb-4 w-100 rounded" alt="Blog Details" />
                                <p className="text-justify lh-lg m-0 p-0 pb-3">ATUM Solar Roof checks all the boxes of being a sustainable, clean energy alternative that powers
                                    your electricity needs. Having great thermal resistance, ATUM itself is certified to minimize heat
                                    transfer into the building, keeping interiors cooler up to 3 degree Celsius.</p>
                                <h4 className="fs-4 fw-semibold m-0 p-0 pb-2">Snow load</h4>
                                <img src={CDN_URL+'visaka/atum/website/assets/images/blogdetails/1-6.jpg'} className="img-fluid mt-3 mb-4 w-100 rounded" alt="Blog Details" />
                                <p className="text-justify lh-lg m-0 p-0 pb-3">Certified by UL, ATUM Solar roof is also resilient to withstand hail and snow. It can bear a live
                                    load of up to 300 kg/sq.ft live load and a dead load that weighs 150 kg/sq.ft, more than adequate to
                                    combat regions with rough winters and piling snow.</p>
                            </div>
                        </div>
                    </div>
                </section>}

            {bdheading === "why-the-world-needs-to-go-solar-now-more-than-ever" &&
                <section className="blogdetails py-5">
                    <div className="container">
                        <div className="row">
                            <div className="w-75 mx-auto d-block">
                                <h2 className="primary-text text-capitalize fs-3 fw-semibold">Why the world needs to go solar now more than ever</h2>
                                <p className="fs-7 text-gray">posted on: may 14, 2020</p>
                                <img src={CDN_URL+'visaka/atum/website/assets/images/blogdetails/2-1.jpg'} className="img-fluid mt-3 mb-4 w-100 rounded" alt="Blog Details" />
                                <h4 className="fs-4 fw-semibold m-0 p-0 pb-2">ATUM Solar Roof is both an affordable and energy-efficient addition to your building. Read on to
                                    know why the right time to go solar is now!</h4>
                                <p className="text-justify lh-lg m-0 p-0 pb-3">The onset of the pandemic has given us enough time to reflect on the choices we’ve made as species.
                                    While some are essential to sustenance, others, however, could have taken a more eco-friendly route.
                                    While it has been a chaotic couple of months for humans, mother Earth is reclaiming her throne;
                                    she’s healing, yet nurturing us with clean air, clear skies, and safe water.</p>
                                <p className="text-justify lh-lg m-0 p-0 pb-3">From sectors of energy generation to construction, the bottom line is common-‘making Earth-conscious
                                    choices’. And as a medium for generating clean energy from a renewable source that can last for ages
                                    together, ATUM is both an affordable and energy-efficient addition to your building. Read on to know
                                    why the right time to go solar is now!</p>
                                <h4 className="fs-4 fw-semibold m-0 p-0 pb-2">Plummeting resources</h4>
                                <img src={CDN_URL+'visaka/atum/website/assets/images/blogdetails/2-2.jpg'} className="img-fluid mt-3 mb-4 w-100 rounded" alt="Blog Details" />
                                <p className="text-justify lh-lg m-0 p-0 pb-3">Coal generates most of the world’s electricity, about 38 percent, but it may soon run out. With
                                    finite resources that struggle to power infinite demand, we are slowly looking to renewable sources
                                    of energy to generate the electricity we need. The percentage of electricity generated using clean
                                    energy sources currently lies at 26.2 percent, and solar merely makes 2.2 percent of it. According
                                    to Forbes, global PV is expected to exceed 100 GW/yr from 2019 and triple that figure by the year
                                    2050. Is it because solar is the most abundant resource on the planet? The answer is affirmative.
                                </p>
                                <h4 className="fs-4 fw-semibold m-0 p-0 pb-2">Undo climate change</h4>
                                <img src={CDN_URL+'visaka/atum/website/assets/images/blogdetails/2-3.jpg'} className="img-fluid mt-3 mb-4 w-100 rounded" alt="Blog Details" />
                                <p className="text-justify lh-lg m-0 p-0 pb-3">Not only does going solar diminish our dependency on fossil fuels, but it also lowers greenhouse
                                    emissions—which is a crucial catalyst for global warming. Did you know with a 100 kW ATUM roof, you
                                    offset 120 tonnes of Carbon Dioxide a year? ATUM also significantly has fewer carbon emissions
                                    during installation than any other typical roof plus solar panel duo.</p>
                                <h4 className="fs-4 fw-semibold m-0 p-0 pb-2">Affordable solar roofing</h4>
                                <img src={CDN_URL+'visaka/atum/website/assets/images/blogdetails/2-4.jpg'} className="img-fluid mt-3 mb-4 w-100 rounded" alt="Blog Details" />
                                <p className="text-justify lh-lg m-0 p-0 pb-3">From what it was 20 years ago, solar is now the most affordable solution to generate green energy.
                                    Not only does ATUM Roof come under the same price bracket of getting a typical roof with solar
                                    panels installed on top of it, but it also fetches a high return on investment. With a payback
                                    period in just 5-6 years for a residential project, ATUM immediately starts earning for you after
                                    payback through government-issued subsidies and rebates—which means the cost of installation,
                                    operation, and maintenance are very economical.</p>
                                <h4 className="fs-4 fw-semibold m-0 p-0 pb-2">All yours, for a lifetime</h4>
                                <img src={CDN_URL+'visaka/atum/website/assets/images/blogdetails/2-5.jpg'} className="img-fluid mt-3 mb-4 w-100 rounded" alt="Blog Details" />
                                <p className="text-justify lh-lg m-0 p-0 pb-3">Not having to pay any power bills, the integrated solar roof is a generous innovation made to sustain
                                    urban power demands. On top of the substantial returns that the solar roof offers for your home,
                                    industry, or office, the most underrated quality is its ability to stay functional for at least 35
                                    years. Certified to withstand heavy winds, hailstorms, and hurricanes, ATUM is also Class A
                                    fire-rated to withstand spread of domestic fires for over 2 hours and 51 minutes.</p>

                            </div>
                        </div>
                    </div>
                </section>}

            {bdheading === "five-reasons-why-you-should-buy-your-solar-rooftop-plant-during-a-recession" &&
                <section className="blogdetails py-5">
                    <div className="container">
                        <div className="row">
                            <div className="w-75 mx-auto d-block">
                                <h2 className="primary-text text-capitalize fs-3 fw-semibold">Five reasons why you should buy your solar rooftop plant during a recession</h2>
                                <p className="fs-7 text-gray">posted on: april 13, 2020 | <span className="website-span-textstyle">Author: Srujan Yeleti</span></p>
                                <img src={CDN_URL+'visaka/atum/website/assets/images/blogdetails/3-1.jpg'} className="img-fluid mt-3 mb-4 w-100 rounded" alt="Blog Details" />
                                <h4 className="fs-4 fw-semibold m-0 p-0 pb-2">1. Minimum investment, maximum gains</h4>
                                <img src={CDN_URL+'visaka/atum/website/assets/images/blogdetails/3-2.jpg'} className="img-fluid mt-3 mb-4 w-100 rounded" alt="Blog Details" />
                                <p className="text-justify lh-lg m-0 p-0 pb-3">With investment into solar power today being merely negligible, there are numerous financing schemes
                                    by banks, NBFCs and private investors that are eager to fund with a minimal interest—which would be
                                    paid back by the savings on solar.</p>
                                <h4 className="fs-4 fw-semibold m-0 p-0 pb-2">2. Cheaper Loans</h4>
                                <img src={CDN_URL+'visaka/atum/website/assets/images/blogdetails/3-3.jpg'} className="img-fluid mt-3 mb-4 w-100 rounded" alt="Blog Details" />
                                <p className="text-justify lh-lg m-0 p-0 pb-3">As governments look to benefit by incentivizing investment during recession or an economic slowdown,
                                    newer loan schemes with lower interest rates and higher tenures are expected to be at offer. While
                                    solar loans are already cheaper or on par with home loans, even a 1 per cent reduction in interest
                                    rate will have huge impact on the payback period.</p>
                                <h4 className="fs-4 fw-semibold m-0 p-0 pb-2">3. Greater, Faster Support from Installers:</h4>
                                <img src={CDN_URL+'visaka/atum/website/assets/images/blogdetails/3-4.jpg'} className="img-fluid mt-3 mb-4 w-100 rounded" alt="Blog Details" />
                                <p className="text-justify lh-lg m-0 p-0 pb-3">It has been always witnessed that getting higher cash flows is given precedence over making good
                                    margins while in a slow-down. This means there is great chance that a lot of installers will be
                                    offering seasonal discounts to keep business on wheels. Also, owing to low activity, your rooftop
                                    project will be attended to on priority, which would have been otherwise during the busy days.</p>
                                <h4 className="fs-4 fw-semibold m-0 p-0 pb-2">4.Savings are sweeter in a slowdown:</h4>
                                <img src={CDN_URL+'visaka/atum/website/assets/images/blogdetails/3-5.jpg'} className="img-fluid mt-3 mb-4 w-100 rounded" alt="Blog Details" />
                                <p className="text-justify lh-lg m-0 p-0 pb-3">Factories usually will be at 60% utilization factor during a slowdown. Commercial complexes will be
                                    grappling to cut costs, residential owners will look to invest in safer bets—like solar. This duly
                                    aids the present scenario, as you will be investing nothing from your pocket and yet saving on
                                    monthly power bills—which is of great significance during a slowdown.</p>
                                <h4 className="fs-4 fw-semibold m-0 p-0 pb-2">5. Bracing for revival:</h4>
                                <img src={CDN_URL+'visaka/atum/website/assets/images/blogdetails/3-6.jpg'} className="img-fluid mt-3 mb-4 w-100 rounded" alt="Blog Details" />
                                <p className="text-justify lh-lg m-0 p-0 pb-3">The end of a slowdown is immediately followed by a massive surge in demand which is usually unmet due
                                    to inadequate preparedness. Preparing for revival means you need to save for a large capital
                                    expenditure and working capital rise immediately after the recession. This is only possible if you
                                    invest now in solar and save for the next 18 months which experts foresee as the time needed for
                                    global economic recovery.</p>
                            </div>
                        </div>
                    </div>
                </section>}

            {bdheading === "why-investing-in-a-solar-roof-is-the-only-trustworthy-scheme-to-make-money" &&
                <section className="blogdetails py-5">
                    <div className="container">
                        <div className="row">
                            <div className="w-75 mx-auto d-block">
                                <h2 className="primary-text text-capitalize fs-3 fw-semibold">Why investing in a Solar roof is the only trustworthy scheme to make money while home!</h2>
                                <p className="fs-7 text-gray">posted on: april 08, 2020 | <span className="website-span-textstyle">Author: Srujan Yeleti</span></p>
                                <img src={CDN_URL+'visaka/atum/website/assets/images/blogdetails/4-1.jpg'} className="img-fluid mt-3 mb-4 w-100 rounded" alt="Blog Details" />
                                <p className="text-justify lh-lg m-0 p-0 pb-3">You might have come across numerous schemes that lure the public, promising a monthly income while
                                    sitting at home. Most of these turn out to be Ponzi schemes, bringing losses to numerous enthusiasts
                                    rich and poor alike. But what nobody has been told, is that one can invest in a solar roof, get back
                                    investment in 2.5 years and keep earning for the rest of the life a sizable secondary income or
                                    savings. This blog post shows you all the math behind it because numbers don't lie.</p>
                                <h4 className="fs-4 fw-semibold m-0 p-0 pb-2">Where is your buck landing?</h4>
                                <p className="text-justify lh-lg m-0 p-0 pb-3">For simplicity, let us say you are a homeowner who has a monthly electricity bill of 3000 Rs, and a
                                    roof area of 350 sq.ft capable of hosting 15 Solar Roof Panels or 5 kW. The cost of constructing a
                                    solar roof would be 500 Rupees per sq.ft, and the rest of the solar equipment and steel structure
                                    would cost another 200 rupees per sq.ft. So you would invest 800 rupees per sq.ft in total. This
                                    would be an investment of Rs 2,20,000 which can be entirely funded by a bank.</p>
                                <h4 className="fs-4 fw-semibold m-0 p-0 pb-2">The world loves solar</h4>
                                <p className="text-justify lh-lg m-0 p-0 pb-3">Paulo Coelho did not think about solar when he said something similar. But it is right for solar
                                    power in context.</p>
                                <p className="text-justify lh-lg m-0 p-0 pb-3">Did you know banks have a mandate to issue solar loans at the same interest rate as that of a home
                                    loan which is the lowest of all loans? Yes, they have a lot of green funds to enable this.</p>
                                <p className="text-justify lh-lg m-0 p-0 pb-3">Similarly, for homeowners government gives Rs 80,000 subsidy on a 5kW system. This makes your total
                                    investment Rs 1,40,000. But since a bank funds you with this loan amount. You will be investing Rs
                                    ZERO.</p>
                                <h4 className="fs-4 fw-semibold m-0 p-0 pb-2">Now that you have invested ZERO, do you Save or do you Earn?</h4>
                                <p className="text-justify lh-lg m-0 p-0 pb-3">Dint your grandmother tell you that every penny saved today is worth ten times someday in the future.
                                </p>
                                <p className="text-justify lh-lg m-0 p-0 pb-3">
                                    Every year your 5kW generates 6750 units or 560 units per month? How? Let us calculate = 5 x 4.5
                                    Units of Electricity/ kW x 300 Average Sunny Days per year.</p>
                                <p className="text-justify lh-lg m-0 p-0 pb-3">
                                    You usually pay Rs 5 or Rs 6 per unit of electricity consumed from the electricity board. But if you
                                    save this by generating solar power, you are saving =560x 5=2800 Rs per month, as you won't be
                                    paying this to the electricity board anymore. So this is savings for 5 years till you pay off your
                                    loan.</p>
                                <p className="text-justify lh-lg m-0 p-0 pb-3">
                                    1,40,000 Rs of loan needs 2800 Rs of EMI for 5 years to pay it off.</p>
                                <p className="text-justify lh-lg m-0 p-0 pb-3">
                                    And this is paying off the roof plus solar, i.e. ATUM and not just the solar power plant. So you are
                                    buying a roof that generates power and a real estate on a 5-year EMI scheme.</p>
                                <p className="text-justify lh-lg m-0 p-0 pb-3">
                                    After 5 years for over 30 years, you earn Rs 2800 Rs on an average though it won't be a constant, as
                                    you won't be paying any electricity bill at all, and your loan is paid off. Rest of the lifetime it
                                    is an earning.</p>
                                <h4 className="fs-4 fw-semibold m-0 p-0 pb-2">What more can I get out of having a solar roof?
                                </h4>
                                <p className="text-justify lh-lg m-0 p-0 pb-3">Of course, have a 350 sq.ft roof on your terrace can mean usable real estate. In many cities, people
                                    rent out space and earn additional income, thereby increasing the monthly earnings. When hospitals
                                    and hotels install a solar roof, they create hostels, bathrooms, storage area under the roof, which
                                    is invaluable in larger cities.
                                </p>
                                <h4 className="fs-4 fw-semibold m-0 p-0 pb-2">Are people investing in solar?
                                </h4>
                                <p className="text-justify lh-lg m-0 p-0 pb-3">Everybody is investing in solar. It is the most reliable, secure, guaranteed way of generating fixed
                                    income for the long term. If you are not aware that billions of dollars are being invested in solar
                                    by large multinationals, you have missed the boat. It is not very late. Buy your ATUM solar roof
                                    today!
                                </p>
                            </div>
                        </div>
                    </div>
                </section>}

            {bdheading === "top-ten-reasons-to-choose-a-solar-roof-and-not-a-typical-solar-panel" &&
                <section className="blogdetails py-5">
                    <div className="container">
                        <div className="row">
                            <div className="w-75 mx-auto d-block">
                                <h2 className="primary-text text-capitalize fs-3 fw-semibold">Top ten reasons to choose a solar roof and not a typical solar panel</h2>
                                <p className="fs-7 text-gray">posted on: april 05, 2020 | <span className="website-span-textstyle">Author: Srujan Yeleti</span></p>
                                <h4 className="fs-4 fw-semibold m-0 p-0 pb-2">Want to know how getting a solar roof can be the best decision you’d ever make? Read on!</h4>
                                <img src={CDN_URL+'visaka/atum/website/assets/images/blogdetails/5-1.jpg'} className="img-fluid mt-3 mb-4 w-100 rounded" alt="Blog Details" />
                                <p className="text-justify lh-lg m-0 p-0 pb-3">Choosing the right solar solution can be a tough call with so many choices around. But your preference has an impact on your finances, building facilities, environment for a lifetime, making it a key determiner for energy independence. While choosing to go solar is undoubtedly a wise decision, you might ask why you should choose a solar roof, and here are our ten reasons that tell you why solar roofs are a necessity more than just a choice.</p>
                                <h4 className="fs-4 fw-semibold m-0 p-0 pb-2">1.Powerful</h4>
                                <img src={CDN_URL+'visaka/atum/website/assets/images/blogdetails/5-2.jpg'} className="img-fluid mt-3 mb-4 w-100 rounded" alt="Blog Details" />
                                <p className="text-justify lh-lg m-0 p-0 pb-3">Whom do you bet on during a boxing match? One with a track record. Right? A retrofit roof made using a metal sheet, and conventional solar panels cannot be trusted to withstand the wind, hail, heat and hurricanes for 30 years, while ATUM Solar Roof is manufactured, tested and certified for these extreme conditions.</p>
                                <h4 className="fs-4 fw-semibold m-0 p-0 pb-2">2.Sleek, all-in-one</h4>
                                <img src={CDN_URL+'visaka/atum/website/assets/images/blogdetails/5-3.jpg'} className="img-fluid mt-3 mb-4 w-100 rounded" alt="Blog Details" />
                                <p className="text-justify lh-lg m-0 p-0 pb-3">ATUM has a roofing sheet made of cement and a solar cell embedded into it, making it a single product with all the features of a roof and solar panel. This seamless sophistication is not possible using typical panels in the market.</p>
                                <h4 className="fs-4 fw-semibold m-0 p-0 pb-2">3. It is Cool</h4>
                                <img src={CDN_URL+'visaka/atum/website/assets/images/blogdetails/5-4.jpg'} className="img-fluid mt-3 mb-4 w-100 rounded" alt="Blog Details" />
                                <p className="text-justify lh-lg m-0 p-0 pb-3">Not just by the looks of it, but ATUM also keeps your interiors cooler by 5° Celsius. While compared to a metal sheet mounted with solar panels, it's a cool choice to make.</p>
                                <h4 className="fs-4 fw-semibold m-0 p-0 pb-2">4. Most trusted</h4>
                                <p className="text-justify lh-lg m-0 p-0 pb-3">ATUM is the only solar roof which has been certified by UL for all the features that a roof and a solar panel should have. The testing has been done in trusted laboratories of UL in Frankfurt, Germany.</p>
                                <h4 className="fs-4 fw-semibold m-0 p-0 pb-2">5. Freedom, at no cost</h4>
                                <img src={CDN_URL+'visaka/atum/website/assets/images/blogdetails/5-5.jpg'} className="img-fluid mt-3 mb-4 w-100 rounded" alt="Blog Details" />
                                <p className="text-justify lh-lg m-0 p-0 pb-3">You save electricity, pay off your solar roof in 2 years, and you are free for a lifetime from the worries of power bills, conserving power, or causing pollution to the environment. It's a massive burden off your mind at a minimal investment which is paid back in a couple of years.</p>
                                <h4 className="fs-4 fw-semibold m-0 p-0 pb-2">6. Who doesn't like some extra real estate?</h4>
                                <img src={CDN_URL+'visaka/atum/website/assets/images/blogdetails/5-6.jpg'} className="img-fluid mt-3 mb-4 w-100 rounded" alt="Blog Details" />
                                <p className="text-justify lh-lg m-0 p-0 pb-3">A roof creates a floor area under it. ATUM creates real estate which is usable, and invaluable for a lifetime. When this value is monetized, you can earn the initial investment into the solar roof back, within 2 years. Don't worry; this is apart from the savings you make on electricity.</p>
                                <h4 className="fs-4 fw-semibold m-0 p-0 pb-2">7. Unmatched aesthetics</h4>
                                <p className="text-justify lh-lg m-0 p-0 pb-3">ATUM makes your roofing beautiful. It can seamlessly integrate into any roofing material, making it versatile. When you are investing in something which goes on to your roof, it better be a beautiful one.</p>
                                <h4 className="fs-4 fw-semibold m-0 p-0 pb-2">8. BTS-The Brains behind it</h4>
                                <p className="text-justify lh-lg m-0 p-0 pb-3">You trust a product not just for what it is, but also for who made it. ATUM is manufactured by Visaka, India's first and largest roofing and sustainable building materials manufacturer.</p>
                                <h4 className="fs-4 fw-semibold m-0 p-0 pb-2">9. Data</h4>
                                <p className="text-justify lh-lg m-0 p-0 pb-3">If all this seems too good to be true, we have data that speaks otherwise. Ask us for it, and we'd fill you in with numerous recommendations from the clients who are using ATUM.</p>
                                <h4 className="fs-4 fw-semibold m-0 p-0 pb-2">10. Give back to mother Earth</h4>
                                <img src={CDN_URL+'visaka/atum/website/assets/images/blogdetails/5-7.jpg'} className="img-fluid mt-3 mb-4 w-100 rounded" alt="Blog Details" />
                                <p className="text-justify lh-lg m-0 p-0 pb-3">ATUM makes your roofing beautiful. It can seamlessly integrate into any roofing material, making it versatile. When you are investing in something which goes on to your roof, it better be a beautiful one.</p>
                            </div>
                        </div>
                    </div>
                </section>}

            {bdheading === "solar-glossary-101-what-you-need-to-know" &&
                <section className="blogdetails py-5">
                    <div className="container">
                        <div className="row">
                            <div className="w-75 mx-auto d-block">
                                <h2 className="primary-text text-capitalize fs-3 fw-semibold">Solar Glossary 101: What You Need To Know</h2>
                                <p className="fs-7 text-gray">posted on: april 03, 2020 | <span className="website-span-textstyle">Author: Subha Priyadarshini</span></p>
                                <img src={CDN_URL+'visaka/atum/website/assets/images/blogdetails/6-1.jpg'} className="img-fluid mt-3 mb-4 w-100 rounded" alt="Blog Details" />
                                <h4 className="fs-4 fw-semibold m-0 p-0 pb-2">An easy guide on basic solar jargons to help your investment in a safe solar roof</h4>
                                <p className="text-justify lh-lg m-0 p-0 pb-3">When a pandemic hits the world, self-isolation gives all the time to reflect on investing in gen-next tech systems that best complement your lifestyle. One such fail-proof investment is a solar roof that keeps your home powered even during the toughest of adversaries. However, a basic understanding of the solar jargon would save you time, money and effort in making the best choice for your home, business or industry. With this list of commonly used solar slang, brush up your flare(pun intended) for the Sun!</p>
                                <h4 className="fs-4 fw-semibold m-0 p-0 pb-2">Solar Panel</h4>
                                <p className="text-justify lh-lg m-0 p-0 pb-3">Multiple solar cells are interlinked to form a circuit— which is then mounted on a framework to make a Solar Panel, aka the PV Module. When someone says 'Photovoltaic Module', they 'technically' mean that the slab relies on sunlight to generate Direct Current(DC) electricity.</p>
                                <h4 className="fs-4 fw-semibold m-0 p-0 pb-2">Efficiency</h4>
                                <p className="text-justify lh-lg m-0 p-0 pb-3">If all solar panels just converted sunlight into electricity, then any solar is a good deal, right? Wrong! You might just be investing in panels that are not efficient enough. Efficiency is the percentage of sunlight that a panel can convert into usable electricity. From having 6% efficiency to 16-18%, conventional solar panels have come a long way. The higher the efficiency, the fewer panels you'd need and the higher the return on investment.</p>
                                <h4 className="fs-4 fw-semibold m-0 p-0 pb-2">Inverter</h4>
                                <p className="text-justify lh-lg m-0 p-0 pb-3">Barring a few contraptions like air conditioners, washing machines or UPS, Alternating Current(AC) powers most electrical appliances; and since a solar system generates DC, the inverter turns DC to AC, making the power usable for the grid, as well as other off-grid networks.</p>
                                <h4 className="fs-4 fw-semibold m-0 p-0 pb-2">Building-Integrated Photovoltaics (BIPV)</h4>
                                <p className="text-justify lh-lg m-0 p-0 pb-3">A giveaway from its name, a Building-Integrated Photovoltaics (BIPV) is fabricated into the building to meet its power needs. The solar modules used here replace conventional building materials, seamlessly blending into the design and functionality of a construction model. Although rare, ATUM is a BIPV that's fast-dominating construction industry's roofing sector with its integrated roofing solution with its leak-proof, weather-resistant properties.</p>
                                <h4 className="fs-4 fw-semibold m-0 p-0 pb-2">Payback Period</h4>
                                <p className="text-justify lh-lg m-0 p-0 pb-3">Calculate your Payback Period! Payback in just 5-6 years! Been hearing these statements lately? It's time to be in the know! The payback period of a solar roof is the time taken for your solar roof to pay for itself. With a shorter payback period like that of ATUM, you'd be offsetting your power bills with solar power and go energy independent, sooner than ever!</p>
                                <h4 className="fs-4 fw-semibold m-0 p-0 pb-2">Rooftop Solar</h4>
                                <p className="text-justify lh-lg m-0 p-0 pb-3">Unlike ground-mount solar that is an array of panels placed on the earth for large-scale, commercial power generation, rooftop solar valuable real-estate space that adds value to your property. Rooftop solar is mounted atop roofs but has the disadvantage of chipping and breakage from extreme winds, rain and hail. ATUM is a sturdy, functional roof that generates power, which means no more painfully thin hinges that can't stand the test of time.</p>
                            </div>
                        </div>
                    </div>
                </section>}

            {bdheading === "why-going-solar-is-the-best-alt-energy-solution-for-the-future" &&
                <section className="blogdetails py-5">
                    <div className="container">
                        <div className="row">
                            <div className="w-75 mx-auto d-block">
                                <h2 className="primary-text text-capitalize fs-3 fw-semibold">Why going solar is the best alt energy solution for the future</h2>
                                <p className="fs-7 text-gray">posted on: january 04, 2020 | <span className="website-span-textstyle">Author: Subha Priyadarshini</span></p>
                                <img src={CDN_URL+'visaka/atum/website/assets/images/blogdetails/7-1.jpg'} className="img-fluid mt-3 mb-4 w-100 rounded" alt="Blog Details" />
                                <h4 className="fs-4 fw-semibold m-0 p-0 pb-2">Rooftop solar is taking over the globe like never before. Thinking twice to switch to solar? We list down five reasons why solar PV modules are a bright idea.</h4>
                                <p className="text-justify lh-lg m-0 p-0 pb-3">With the solar industry making strides in turning power demands of the world 'clean', the adaptation of solar technology has seen its share of hits and misses over the last decade. Multiple subsidies, sustainability slogans and climate change campaigns later, we have learned to see the Sun in its glory, judging solar energy to be the cleanest alternative to meet rising power demands. It's 2020 and it's time that your choices reflect your sustainable lifestyle. Want to know the best way to do it? Just solarize it!</p>
                                <h4 className="fs-4 fw-semibold m-0 p-0 pb-2">Here are five facts that tell you why you should be future-ready:</h4>
                                <h4 className="fs-4 fw-semibold m-0 p-0 pb-2">Solar Surplus</h4>
                                <p className="text-justify lh-lg m-0 p-0 pb-3">One hour of energy from the Sun is all you need to power the global populace for a year. However, mankind can currently utilise a mere 23% of the sun's energy to power the globe but the transition is still in the making. Sunlight is everywhere and it is infinitely renewable; except if you live on the north and south poles of the moon. Meaning, one can harness the sun's energy to power buildings even in places with no access to electricity. And unlike wind energy, generating solar power is noiseless and hassle-free.</p>
                                <p className="text-justify lh-lg m-0 p-0 pb-3">In countries like India that has nearly 300 clear, sunny days in a year, the future for solar-powered homes seems brighter than ever.</p>
                                <h4 className="fs-4 fw-semibold m-0 p-0 pb-2">Cloud Cover?</h4>
                                <p className="text-justify lh-lg m-0 p-0 pb-3">Contradictory to the popular misconception, Solar PV (photovoltaic) modules produce electricity even in the absence of clear skies. Clouds or no clouds, your solar roof will remain equally efficient.</p>
                                <p className="text-justify lh-lg m-0 p-0 pb-3">Like most gadgets, PV modules also have their hours of peak productivity. They can be most productive between 11 am-4 pm and your property will be off-the-grid and immune to power blackouts. Oh, did we mention that they are effortless to maintain?</p>
                                <h4 className="fs-4 fw-semibold m-0 p-0 pb-2">Green Economy</h4>
                                <p className="text-justify lh-lg m-0 p-0 pb-3">Another compelling reason to switch to solar is that it leaves a minimal carbon footprint and directly combats climate change. Our reserves of fast-depleting fossil fuels could hardly power us for another century, and they are neither clean nor free.</p>
                                <p className="text-justify lh-lg m-0 p-0 pb-3">For those seeking a carbon offset for their industry or home, a solar roof is an ideal way to begin. Revenue generated from generating clean energy contributes to the green economy that many nations tirelessly advocate.</p>
                                <p className="text-justify lh-lg m-0 p-0 pb-3">“What is a Green Economy?” - you may ask. It is an economic design that is made to focus on reducing environmental risks whilst seeking sustainable development.</p>
                                <h4 className="fs-4 fw-semibold m-0 p-0 pb-2">Easy on the Wallet</h4>
                                <p className="text-justify lh-lg m-0 p-0 pb-3">You heard it. Solar systems are inarguably the best way to rid your homes of rising utility bills. While it does feel good to not pay for the power you use, rooftop solar also helps you earn while you save.</p>
                                <p className="text-justify lh-lg m-0 p-0 pb-3">The solar industry is the most affordable than it has ever been in the last decade. With zero installation costs, solar can now be rented, leased or owned.</p>
                                <h4 className="fs-4 fw-semibold m-0 p-0 pb-2">Quality Real Estate</h4>
                                <p className="text-justify lh-lg m-0 p-0 pb-3">The greener the construction practices of a building, the higher the real estate value. An integrated solar roof, however, goes a step ahead and creates extra space that can contribute to your income from the property.</p>
                                <p className="text-justify lh-lg m-0 p-0 pb-3">Vastly underutilised lands and buildings can be used to generate extra revenue for a lifetime. Most of all, it's a free shelter that generates electricity for you!</p>
                            </div>
                        </div>
                    </div>
                </section>}
            <Footer/>
        </div>
    )
}

export default BlogDetails