import React from 'react';
import { Link } from 'react-router-dom';

const Error = () => {
  return (
    <div>
        <div className='errorForm'>
          <div className='position-absolute top-50 start-50 translate-middle text-center shadow p-5 rounded bg-white successPage'>
              <div className='icon mb-4'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="80px" fill="red"><path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z"/></svg>
              </div>
              <div className='details'>
                  <h2 className='text-dark fs-2 fw-bold'>Error</h2>
                  <p className='fs-7 text-dark'>Something went wronge, Please try again later.</p>
              </div>
              <div className='goToHomeBtn rounded  py-2 px-3'>
                  <Link to='/integrated-solar-roof' className='btn btn-danger fw-semibold global-primary-bg m-0 p-0 px-3 py-2 white-text'>Go To Home Page</Link>
              </div>
          </div>
        </div>
    </div>
  )
}

export default Error