import React, { useEffect, useState } from 'react';
import { InsideBannerList } from './Innerbanner';
import { CDN_URL } from '../../../Config';
import Header from '../common/Header';
import Footer from '../common/Footer';

const About = () => {
  const [state, setState] = useState({});

  useEffect(() => {
    let aboutInsideSlider = InsideBannerList.filter((item) => item.title === 'about');
    setState(aboutInsideSlider[0]);
  }, []);

  return (
    <div>
      <Header/>
        <section className="inner-banner" style={{ backgroundImage: `url(${state.image})` }} >
          <div className="container">
            <div className="row">
              <h2 className="text-white text-uppearcase inner-banner-title fs-1 fw-semibold text-center position-absolute top-50 start-50 translate-middle">{state.heading}</h2>
            </div>
          </div>
        </section>
        <section className="aboutus py-5">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center pb-4">
                <h2 className="primary-text text-capitalize all-heading-text">What is ATUM?</h2>
                <p className="fs-7 text-gray">A breakthrough invention in the field of sustainable energy</p>
              </div>
            </div>
            <div className="row text-center text-lg-start text-md-center">
              <div className="col-lg-6 col-12">
                <div className="about-right px-0 py-3 pt-lg-0 lh-2">
                  <p className="website-para-textstyle text-justify">Atum is an integrated Solar Roofing System. It serves all the functions of a traditional roof. Whilst generating energy for you. Atum is a building integrated solar PV panel with a cementations substrate that reduces thermal conductivity better than a traditional roof. It is an aesthetic and elegant roofing solution.</p>
                  <p className="website-para-textstyle text-justify">Imagine a world where you generate, manage and control the power of your industry or home. A world where power cuts don't exist & the control lies on your mobile phone. A world where eco-friendly & sustainble energy is a reality. A world that runs on Atum.</p>
                  <p className="website-para-textstyle text-justify">We manufacture integrated solar panels with a cement base. It reduces thermal conductivity better than a traditional roof while enhancing the appearance of your roof.</p>
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="about-left hm-abt d-lg-block mb-4 mb-lg-0 abt-bg"
                  style={{ backgroundImage: `url(${CDN_URL+'visaka/atum/website/assets/images/about/2.jpg'})` }}></div>
              </div>
            </div>
          </div>
        </section>
        <section className="atum-different">
          <div className="container-fluid">
            <div className="row column-reverse shadow-none">
              <div className="col-md-12 col-lg-6 col-12 h-180 bg3" style={{ backgroundImage: `url(${CDN_URL+'visaka/atum/website/assets/images/about/3.png'})` }}>
              </div>
              <div className="col-lg-6 col-12 py-5 product-hilights ps-lg-5 bg-gray">
              <h2 className="primary-text text-capitalize all-heading-text pb-3">What makes ATUM different?</h2>
                <ul className="list-unstyled">
                  <li className="mb-2 d-flex"><span className="icon-font primary-text fs-6 fw-normal pe-2">&#10004;</span>
                    <span className="website-span-textstyle">Compared to GV sheets, ATUM Solar Roof generates no noise and minimises the effects of heat and rain</span>
                  </li>
                  <li className="mb-3 d-flex align-items-center"><span className="icon-font primary-text fs-6 fw-normal pe-2">&#10004;</span><span className="website-span-textstyle">No need for an insulation mat under the roof</span></li>
                  <li className="mb-3 d-flex align-items-center"><span className="icon-font primary-text fs-6 fw-normal pe-2">&#10004;</span><span className="website-span-textstyle">No rust formation; product lasts for more than 25 years
                  </span></li>
                  <li className="mb-3 d-flex align-items-center"><span className="icon-font primary-text fs-6 fw-normal pe-2">&#10004;</span><span className="website-span-textstyle">Fire-resistant and Electric Shock Proof compared to GV sheets which are dangerous and can cause shock and electrocution
                  </span></li>
                  <li className="mb-3 d-flex align-items-center"><span className="icon-font primary-text fs-6 fw-normal pe-2">&#10004;</span><span className="website-span-textstyle">Non-hazardous material, unlike GV sheets which conduct radiations and may cause health hazards
                  </span></li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section className="py-5 product-hilights">
          <div className="container">
            <div className="row align-items-center d-flex">
              <div className="col-lg-6 col-12">
              <h2 className="primary-text text-capitalize all-heading-text pb-3">Product Highlights</h2>
                <ul className="list-unstyled">
                  <li className="mb-2 d-flex"><span className="icon-font primary-text fs-6 fw-normal pe-2">&#10004;</span>
                    <span className="website-span-textstyle">Solar Board with a density of 1300kg/cubic metre, thus increasing the lifespan</span>
                  </li>
                  <li className="mb-3 d-flex align-items-center"><span className="icon-font primary-text fs-6 fw-normal pe-2">&#10004;</span><span className="website-span-textstyle">Increased efficiency due to thermal conductivity of 0.072 W/mtK</span></li>
                  <li className="mb-3 d-flex align-items-center"><span className="icon-font primary-text fs-6 fw-normal pe-2">&#10004;</span><span className="website-span-textstyle">Thermal Insulation reduces the temperatures by 35-40%</span></li>
                  <li className="mb-3 d-flex align-items-center"><span className="icon-font primary-text fs-6 fw-normal pe-2">&#10004;</span><span className="website-span-textstyle">Customisable sizes for larger orders</span></li>
                  <li className="mb-3 d-flex align-items-center"><span className="icon-font primary-text fs-6 fw-normal pe-2">&#10004;</span><span className="website-span-textstyle">Partnerships with battery and inverter manufacturers to make the process falter ahecksier</span></li>
                  <li className="mb-3 d-flex align-items-center"><span className="icon-font primary-text fs-6 fw-normal pe-2">&#10004;</span><span className="website-span-textstyle">It can be used on the building face to gain maximum surface area and increased efficiency</span></li>
                  <li className="mb-3 d-flex align-items-center"><span className="icon-font primary-text fs-6 fw-normal pe-2">&#10004;</span><span className="website-span-textstyle">Recover your investment in just 2.5 years</span></li>
                </ul>
              </div>
              <div className="col-lg-6 col-12 text-center">
                <img src={CDN_URL+'visaka/atum/website/assets/images/about/4.png'} className="img-fluid product-highlights-img" alt="atum_panel_varients" />
              </div>
            </div>
          </div>
        </section>
      <Footer/>
    </div>
  )
}

export default About